.tablePrincipal {
 width: 100%;
 transition: all 1s ease-in-out;
 box-shadow: 1px 1px 4px 2px rgba(0, 63, 128, 0.08);
 border-radius: 15px;
}
.tableHeader {
 background-color: #005dbf;
 font-family: "PT Sans Caption";
 font-size: 15px;
 color: #fff;
 transition: all 1s ease-in-out;

}

.tableThRight {
 background-color: #005dbf;
 font-family: "PT Sans Caption";
 font-size: 15px;
 color: #fff;
 border-top-right-radius: 15px;
 transition: all 1s ease-in-out;

}
.tableThLeft {
 background-color: #005dbf;
 font-family: "PT Sans Caption";
 font-size: 15px;
 color: #fff;
 border-top-left-radius: 15px;
 transition: all 1s ease-in-out;

}

.tableRowBody {
 background-color: #fff;
 font-family: "PT Sans Caption";
 font-size: 12px;
 color: #6e6f7c;
 border-bottom: 1px solid #e6ecf2;
 transition: all 0.5s ease-in-out;

}

.tableRowBody:hover {
 background-color: #f5f7fa;
 transition: all 0.5s ease-in-out;

}

.tableBodyAccordion{
        font-family: "PT Sans Caption";
        transition: all 0.5s ease-in-out;
        padding: 0px;
}

.rowIsOpen{
        background-color: #f5f7fa;
        font-family: "PT Sans Caption";
        font-size: 12px;
        color: #6e6f7c;
        border-bottom: 1px solid #e6ecf2;
        transition: all 0.5s ease-in-out;

}

.hideRow{
        display: none;
        min-width: fit-content;
        transition: all 0.5s ease-in-out;

}