.subTitlerf {
    font-family: PT Sans Caption;
    font-style : normal;
    font-weight: normal;
    font-size  : 15px;
    line-height: 19px;
    color      : #005DBF;
    margin     : 0px 0px 20px 40px;
}

.bgColorrf {
    background-color: #F2F7FC;
    border-radius   : 10px;
    padding-left    : 24px;
}

.bgBtn {
    filter       : grayscale(100%);
    color        : #58595B;
    font-weight  : bold;
    text-align   : center;
    width        : 170px;
    border-radius: 10px;
}

.bgBtn:hover {
    filter          : grayscale(0%);
    color           : #005DBF;
    font-weight     : bold;
    background-color: #005DBF20;

}

.inputSearch {
    border          : 1.5px solid rgba(0, 93, 191, 0.1);
    border-radius   : 5px;
    width           : 100%;
    padding         : 0 .5rem;
    height          : 27px;
    color           : #58595b;
    background-color: rgba(0, 93, 191, 0.1);
}

.inputSearch::placeholder {
    color    : #005dbf;
    font-size: 12px;
    opacity  : 0.7;
}
.hoverDetali:hover{
    background-color: #005DBF30;
    width: 100px;
    border-radius: 100%;
}