.tmAdmissionSettingsContainer {
    display: grid;
    width: 100vw;

    grid-template-areas:
        "content"
        "action-buttons";
    grid-template-rows: 1fr auto;
}

.tmAdmissionSettingsContent {
    grid-area: content;
    overflow-y: auto;
    scrollbar-gutter: stable;
    height: calc(100% - 100px);
    padding-bottom: 50px;
}

.tmAdmissionSettingsActionsButtonsContainer {
    grid-area: action-buttons;
    max-height: 100px;
}