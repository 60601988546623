.w90px{
  width: 90px !important;
}

.w100px{
  width: 100px !important;
}

.w110px{
  width: 110px !important;
}

.w120px{
  width: 120px !important;
}

.w140px{
  width: 140px !important;
}
.w160px{
  width: 160px !important;
}