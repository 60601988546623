.document,
.document>* {
    font-size: 0.8rem !important;
    font-family: "PT Sans" !important;

    .print-header {
        font-size: 0.8rem !important;
    }

    .bg-primary-lighter {
        background-color: rgba(#1ABCD2, $alpha: 0.1);
    }
}

@media print {

    html,
    body {
        height: initial !important;
        overflow: initial !important;
        print-color-adjust: exact;
        margin: 0;
    }

}

@page {
    size: auto;
    margin: 6mm 10mm;
}