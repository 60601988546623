
.cardAccPerAppComponent{
    transition: 0.3s all ease-in-out;
    background: #FFFFFF;
    box-shadow: 2px 2px 12px 2px rgba(0, 63, 128, 0.08), 2px 4px 8px 2px rgba(0, 63, 128, 0.04);
    border-radius: 10px;
}

.isOpenAcc{
    transition: 0.3s all ease-in-out;
    background: #F2F7FC;
}  


.iconRow{
    transition: 0.5s all ease-in-out;
    filter: brightness(0) saturate(100%) invert(24%) sepia(39%) saturate(5194%) hue-rotate(199deg) brightness(88%) contrast(101%);
}

.cardBodyTitle{
    padding: 16px;
}

.cardBodyNone{
    height: 0px;
    transition:height 4s linear 2s;
    display: none;
}
.cardBody{
    padding: 16px 32px ;
    max-height:400px;
    overflow-y: scroll;
    transition:height 4s linear 2s;
}

