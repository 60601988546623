@import "../../styleConstants.scss";

.container{
    border: 1px solid #ccc;
    border-radius: 10px;
    margin: 10px 0px;
    & :first-child{
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
    }

    & :last-child{
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
        border-bottom: 0px;
    }
}

.container_item{
    font-size: 14px;
    padding: 6px 8px;
    transition: ease-in-out 300ms;
    border-bottom: 1px solid #ccc;
    display: flex;
    gap: 10px;
    align-items: center;
    &:hover{
        background: #f7f7f7;
        cursor:pointer
    }
}

.filterGray{
    filter: brightness(0.6);
}