@import "../../styleConstants.scss";

.Search {
    border: rgba(0, 93, 191, 0.1);
    background-color: rgba(0, 93, 191, 0.1);
    border-radius: 5px;
    width: 100%;
    padding: 0 0.5rem;
    height: 27px;
    color: #005dbf;
}
.Search::placeholder {
    color: #005dbf70;
}
.btn_1 {
    background-color: #fff !important;
    border: 1.5px solid $clearBlue !important;
    color: $clearBlue !important;
    min-width: 110px;
    font-weight: 700;
    font-size: 15px;
    line-height: 19, 41px;
    padding-top: 6px;
    padding-bottom: 6px;
}
.btn_1:hover {
    background-color: $clearBlue !important;
    border: 1.5px solid $clearBlue !important;
    color: #fff !important;
}

.btn_1:disabled {
    background-color: $clearGray;
    border: 1.5px solid $clearGray;
    color: #fff;
    opacity: 1;
}
.btn_2{
    background-color:$clearBlue  !important;
    border: 1.5px solid $clearBlue !important;
    color: #fff !important;
    min-width: 110px;
    font-weight: 700;
    font-size: 15px;
    line-height: 19,41px;
    padding-top: 6px;
    padding-bottom: 6px;
}

.btn_2:hover{
    background-color: $darkBlue !important;
    border: 1.5px solid $darkBlue !important;
    color:#fff !important;
}

.btn_2:disabled{
    background-color: #fff !important;
    border: 1.5px solid $clearGray !important;
    color:$clearGray !important;
    opacity: 1;
}
.btn_3{
    background-color:$darkBlue  !important;
    border: 1.5px solid $darkBlue !important;
    color: #fff !important;
    min-width: 110px;
    font-weight: 700;
    font-size: 15px;
    line-height: 19,41px;
    padding-top: 6px;
    padding-bottom: 6px;
}

.btn_3:hover{
    background-color: $darkerBlue !important;
    border: 1.5px solid $darkerBlue !important;
    color:#fff !important;
}

.btn_3:disabled{
    background-color: #fff !important;
    border: 1.5px solid $clearGray !important;
    color:$clearGray !important;
    opacity: 1;
}
.btn_4{
    background-color:$darkerBlue  !important;
    border: 1.5px solid $darkerBlue !important;
    color: #fff !important;
    min-width: 110px;
    font-weight: 700;
    font-size: 15px;
    line-height: 19,41px;
    padding-top: 6px;
    padding-bottom: 6px;
}

.btn_4:hover{
    background-color: $clearBlue !important;
    border: 1.5px solid $clearBlue !important;
    color:#fff !important;
}

.btn_4:disabled{
    background-color: #fff !important;
    border: 1.5px solid $clearGray !important;
    color:$clearGray !important;
    opacity: 1;
}