.app_container_calendar_component {
  height: 282px;
  overflow: auto;
}

.app_container_schedule {
  gap: 0.7rem;
  display: flex;
  margin-bottom: 0.6rem;
}

.app_container_render {
  width: 100%;
  max-width: 250px;
}

.app_container_map {
  gap: 0.4rem;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 0.3rem;
}

.app_title {
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  color: #6e6f7c;
  font-family: 'PT Sans Caption';
}

.app_title_sytem {
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  color: #6e6f7c;
  text-transform: lowercase;
  font-family: 'PT Sans Caption';
}

.app_container_hours {
  width: 100%;
  display: flex;
  max-width: 53px;
  cursor: pointer;
  border-radius: 5px;
  align-items: center;
  border-radius: 5px;
  background: #ffffff;
  flex-direction: column;
  padding: 0.3rem 0.8rem;
  justify-content: center;
  border: 1px solid #00b4cc;
  color: #6e6f7c;
}

.app_container_hours_multi {
  width: 100%;
  display: flex;
  max-width: 53px;
  cursor: pointer;
  border-radius: 5px;
  align-items: center;
  border-radius: 5px;
  background: #ffffff;
  flex-direction: column;
  padding: 0.3rem 0.8rem;
  justify-content: center;
  border: 1px solid #f39682;
}

.app_container_hours_active {
  width: 100%;
  display: flex;
  max-width: 53px;
  cursor: pointer;
  border-radius: 5px;
  align-items: center;
  border-radius: 5px;
  background: #00b4cc;
  flex-direction: column;
  padding: 0.3rem 0.8rem;
  justify-content: center;
  color: white;
}

.app_container_hours_inactive {
  width: 100%;
  display: flex;
  max-width: 53px;
  cursor: pointer;
  border-radius: 5px;
  align-items: center;
  border-radius: 5px;
  background: #d6e0eb;
  flex-direction: column;
  padding: 0.3rem 0.8rem;
  justify-content: center;
  color: #6e6f7c;
  border: 1px solid #00b4cc;
}

.app_container_hours_active_multi {
  width: 100%;
  display: flex;
  max-width: 53px;
  cursor: pointer;
  border-radius: 5px;
  align-items: center;
  border-radius: 5px;
  background: #f39682;
  flex-direction: column;
  padding: 0.3rem 0.8rem;
  justify-content: center;
}

.hours {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  font-family: 'PT Sans Caption';
}

.type {
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  text-transform: lowercase;
  font-family: 'PT Sans Caption';
}

.app_container_left_hours {
  width: 100%;
  display: flex;
  min-width: 40px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.line {
  border-right: 1px solid black;
}

.app_container_sum {
  display: flex;
  padding: 0.2rem;
  cursor: pointer;
  border-radius: 5px;
  align-items: center;
  background: #ffffff;
  justify-content: center;
  border: 1px solid #d6e0eb;
}

.app_card_doctor {
  color: #6e6f7c !important;
  gap: 0.5rem;
  display: flex;
  padding: 1rem;
  cursor: pointer;
  border-radius: 10px;
  margin-bottom: 1rem;
  background: #ffffff;
  border: 1px solid #ccd9e6;
  box-shadow: 0px 0px 6px rgba(43, 71, 139, 0.15);
}

.app_card_doctor_multi {
  gap: 1rem;
  display: flex;
  padding: 1rem;
  cursor: pointer;
  border-radius: 10px;
  margin-bottom: 1rem;
  background: #ffffff;
  border: 1px solid #f39682;
  box-shadow: 0px 0px 6px rgba(43, 71, 139, 0.15);
}

.app_card_doctor_active {
  color: white !important;
  gap: 0.5rem;
  display: flex;
  padding: 1rem;
  cursor: pointer;
  border-radius: 10px;
  margin-bottom: 1rem;
  background: #00b4cc;
  border: 1px solid #ccd9e6;
  box-shadow: 0px 0px 6px rgba(43, 71, 139, 0.15);
}

.app_card_doctor_active img,
.app_card_doctor_active_multi img {
  filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(204deg) brightness(103%) contrast(104%);
}

.app_card_doctor_active_multi {
  gap: 1rem;
  display: flex;
  padding: 1rem;
  cursor: pointer;
  border-radius: 10px;
  margin-bottom: 1rem;
  background: #f39682;
  border: 1px solid #f39682;
  box-shadow: 0px 0px 6px rgba(43, 71, 139, 0.15);
}

.app_container_img {
  width: 40px;
  height: 40px;
  border-radius: 6px;
}

.container_name_doctor {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.app_title_card {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: #6e6f7c;
  font-family: 'PT Sans Caption';
}

.app_site_card {
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  color: #6e6f7c;
  font-family: 'PT Sans Caption';
}

.app_container_calendar {
  width: 100%;
  padding: 1rem;
  margin: auto;
  border-radius: 10px;
  background: #e6f8fa;
  border: 1px solid #a3e4ec;
}

.app_container_calendar_multi {
  width: 100%;
  padding: 1rem;
  margin: auto;
  overflow: hidden;
  max-width: 900px;
  border-radius: 10px;
  background: #fef7f5;
  border: 1px solid #f7b4a5;
}

.app_transition {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  -ms-transition: all 1s ease;
  transition: all 1s ease;
}

.app_container_content_form {
  display: flex;
  align-items: center;
  justify-content: center;
}

.app_line_search {
  border: 1px solid #a3e4ec;
}

.app_line_search_multi {
  border: 1px solid #fce6e1;
}

.app_title {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  color: #1a538d;
  font-family: 'PT Sans Caption';
}

.app_title_search {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #00b4cc;
  cursor: pointer;
  font-family: 'PT Sans Caption';
  text-decoration-line: underline;
}

.app_title_search_multi {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #f39682;
  cursor: pointer;
  font-family: 'PT Sans Caption';
  text-decoration-line: underline;
}

.app_container_calendar_component {
  height: 282px;
  overflow: auto;
}

.app_container_card_component {
  height: 282px;
  overflow: auto;
}

.container {
  gap: 0.3rem;
  display: flex;
}

// --------------------- SEARCH STYLES -------------------------------
.filterSearchCard {
  background: #e6f8fa;
  border-radius: 10px;
  margin: 1rem 0.5rem;
  padding: 1rem;
}

.selectLabelsSearch {
  font-style: normal;
  font-size: 14px;
  color: #1a538d;
  font-family: 'PT Sans Caption';
}

.sdp {
  --theme-color: red !important;
}

.textDanger {
  color: #f39682 !important;
}