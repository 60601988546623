@import "../../styleConstants.scss";
.div {
    display: flex;
}

.div1 {
    width: 70%;
}

.display-grid {
    display: grid;
}

.selectMonth {
    background-repeat: no-repeat;
    background-position: 10% center;
    background-color: $darkerBlue;
    color: $white;
    border-radius: 10px;
    padding-left: 45%;
}

table.dataTable thead .sorting:after,
table.dataTable thead .sorting:before,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_desc_disabled:after,
table.dataTable thead .sorting_desc_disabled:before {
    bottom: 0.5em;
}

.center {
    display: block;
    margin: 0 auto;
}
.divbill {
    margin-left: 10px;
    padding: 10px;
    width: 320px;
    border-left: solid 1.5px #cecece;
}
.titlebill {
    color: #005dbf;
    font-weight: bold;
    font-size: 16px;
    font-family: "PT Sans", sans-serif;
}
.numberbill {
    font-size: 12px;
}
.hover-blue {
    color: #58595b;
}
.hover-blue:hover {
    color: #01a0f6;
}

.tooltip-text .tooltip-content {
    visibility: hidden;
    width: max-content;
    font-size: 10px;
    display: inline-block;
    padding: 5px;
    color: #58595b;
    position: absolute;
    z-index: 1;
    margin-left: 10px;
    background-color: #ffffff;
    box-shadow: 0px 1px 4px rgba(0, 63, 128, 0.35);
    border-radius: 6px 6px 6px 0px;
}

.tooltip-text:hover .tooltip-content {
    visibility: visible;
}
.botton-create {
    color: #58595b;
    filter: grayscale(100%);
    width: 170px;
    height: 40px;
    border-radius: 10px;
}

.botton-create:hover {
    filter: grayscale(0%);
    background-color: #e6eff9;
    color: #005dbf;
}
.bg-table > td {
    background-color: #ffffff !important ;
    border-bottom: solid 1px #cecece80 !important;
}

