@import "../../index.scss";

.container1 {
  display: flex;
  flex-direction: row;
}

.throwThisToTheBottom {
  display: flex;
  flex-direction: column-reverse;
}

.textToTheEnd {
  display: flex;
  justify-content: flex-end;
}

//zone of new angie made tables
.container2 {
  //flex:2;
  //margin-top:15px;
  display: flex;
  flex-direction: column;
}


.centeredContainer {
  width: 100%;
  padding: 0 6rem;
  display: flex;
  flex-direction: column;
}

.imgdisabled {
  filter: grayscale(100%) opacity(50%);
}

.backgroundImage {
  background-image: url("../../assets/img/icons/modalsearch.svg");
  background-repeat: no-repeat;
  background-position: 96%;
  padding-right: 2px;
  background-color: #fff;
}

.hoverinputs:hover {
  border: #cecece solid 1px !important;
  background-color: transparent;
  // background-color: green;
}

.btnPrimary {
  background-color: $clearBlue;
  border: 1.5px solid $clearBlue;
  font-family: "PT Sans";
  color: #fff;
  min-width: 110px;
  font-weight: 700;
  font-size: 15px;
  line-height: 19.41px;
  padding-top: 6px;
  padding-bottom: 6px;
  border-radius: 5px;
}

.btnPrimary:hover {
  background-color: $darkBlue;
  border: 1.5px solid $darkBlue;
  color: #fff;
}

.btnPrimary:disabled {
  background-color: $clearGray;
  border: 1.5px solid $clearGray;
  color: #fff;
  opacity: 1;
}

.btnPrimaryDark {
  background-color: $darkBlue;
  border: 1.5px solid $darkBlue;
  font-family: "PT Sans";
  color: #fff;
  min-width: 110px;
  font-weight: 700;
  font-size: 15px;
  line-height: 19.41px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.btnPrimaryDark:hover {
  background-color: $clearBlue;
  border: 1.5px solid $clearBlue;
  color: #fff;
}

.btnPrimaryDark:disabled {
  background-color: $clearGray;
  border: 1.5px solid $clearGray;
  color: #fff;
  opacity: 1;
}

.btnSecondary {
  background-color: #fff;
  border: 1.5px solid $clearBlue;
  font-family: "PT Sans";
  color: $clearBlue;
  min-width: 110px;
  font-weight: 700;
  font-size: 15px;
  line-height: 19.41px;
  padding-top: 6px;
  padding-bottom: 6px;
  border-radius: 5px;
}

.btnSecondary:hover {
  background-color: $clearBlue;
  border: 1.5px solid $clearBlue;
  color: #fff;
}

.btnSecondary:disabled {
  background-color: #fff;
  border: 1.5px solid $clearGray;
  color: $clearGray;
  opacity: 1;
}
