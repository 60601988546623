.custom-tab {
    min-width: 200px !important;
    color: #005dbf;
    background-color: #F5F7FA;
    border-top-right-radius: 10px !important;
    border-top-left-radius: 10px !important;
    text-align: center;

    &.active {
        color: #005dbf !important;
        background-color: white;
    }
}