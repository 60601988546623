.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.rem1 {
  padding-right: 3rem !important;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.swal2-container {
  z-index: 20000 !important;
}

/* Modal */
.ReactModalPortal > div {
  opacity: 0;
}

.ReactModalPortal .ReactModal__Overlay {
  align-items: center;
  display: flex;
  justify-content: center;
  transition: opacity 0.2s ease-in-out;
  z-index: 999;
}

.modal-fondo {
  background-color: rgba(0, 0, 0, 0.3);
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  position: fixed;
}

.ReactModalPortal .ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModalPortal .ReactModal__Overlay--before-close {
  opacity: 0;
}

/*
.modal {
  background: white;
  border-radius: 20px;
  color: rgb(51, 51, 51);
  display: inline;
  max-width: 490px;
  outline: none;
  padding: 30px 25px;
} */

/* FABS */
.fab {
  border-radius: 100%;
  bottom: 25px;
  font-size: 30px;
  padding: 25px;
  position: fixed;
  right: 25px;
}

.fab-danger {
  border-radius: 100%;
  bottom: 25px;
  font-size: 22px;
  position: fixed;
  left: 25px;
  width: 70px;
  height: 70px;
}

/* .rbc-timeslot-group {
  min-height: 60px !important;
  display: flex;
  align-items: flex-end;
}
 */

.rbc-event {
  width: 100% !important;
  left: 0% !important;
  /* height: 8px !important; */
  /* padding: 0px !important; */
  align-items: center !important;
}

.rbc-time-slot {
  display: flex;
  align-items: flex-end;
}

.rbc-toolbar button {
  border: 0px !important;
}

.rbc-btn-group {
  border: 0px !important;
  color: #003f80 !important;
  display: flex;
  justify-content: space-evenly;
}

.rbc-show-more {
  float: right;
  padding: 4px !important;
}

.rbc-toolbar span:last-child {
  width: 245px;
  display: flex;
  justify-content: space-evenly;
}

.rbc-toolbar span:first-child {
  width: 250px;
}

.rbc-btn-group button:nth-child(1) {
  order: 3;
}

.rbc-btn-group button:nth-child(2) {
  order: 2;
}

.rbc-btn-group button:nth-child(3) {
  order: 1;
}

.rbc-btn-group button {
  border-radius: 10px !important;
  color: #003f80 !important;
  font-weight: bold;
}

.rbc-toolbar span:nth-child(1) button:focus {
  color: #00b4cc !important;
  background-color: #d6f3f7 !important;
}

.rbc-toolbar button:focus {
  background-color: none;
}

.rbc-toolbar span:nth-child(1) {
  display: flex;
}

.rbc-toolbar span:nth-child(1) button:nth-child(2),
.rbc-toolbar span:nth-child(1) button:nth-child(3) {
  color: #a2a3ab !important;
  font-weight: bold;
}

.rbc-toolbar span:nth-child(1) button:nth-child(1) {
  order: 2;
}

.rbc-toolbar span:nth-child(1) button:nth-child(2) {
  order: 1;
}

.rbc-toolbar span:nth-child(1) button:nth-child(3) {
  order: 3;
}

.rbc-toolbar :nth-child(3) button:nth-child(1) {
  order: 3;
}

.rbc-toolbar :nth-child(3) button:nth-child(2) {
  order: 2;
}

.rbc-toolbar :nth-child(3) button:nth-child(3) {
  order: 1;
}

.rbc-toolbar span:nth-child(3) {
  width: 250px;
  column-gap: 10px;
  display: flex;
  justify-content: space-around;
}

.rbc-toolbar button:active,
.rbc-toolbar button.rbc-active {
  background-color: #d6f3f7 !important;
  box-shadow: none !important;
  color: #003f80 !important;
}

.rbc-toolbar button:hover {
  background-color: #d6f3f7 !important;
  box-shadow: 0px !important;
}

.rbc-toolbar:nth-child(1) {
  display: flex;
}

.rbc-time-view,
.rbc-month-view {
  background-color: white !important;
}

.rbc-month-view {
  /* border: none !important; */
  border-bottom-left-radius: 23px;
}

.rbc-month-view > :last-child {
  border-bottom-left-radius: 20px;
}

.rbc-time-view {
  border: 1px solid #00b4cc !important;
  padding: 10px 20px !important;
  border-radius: 20px !important;
  padding-top: 0px !important;
}

.modalTitle {
  color: #00b4cc !important;
  font-weight: bold;
}

.boxModal {
  color: #6e6f7c;
  display: grid;
  grid-template-columns: 25px 1fr;
  column-gap: 5px;
  font-size: 14px;
  align-items: center;
}

.boxModal2 {
  color: #6e6f7c;
  font-size: 14px;
}

.rbc-label {
  color: #a3bad1 !important;
}

.rbc-day-slot .rbc-events-container {
  border-left: 1px solid #ebebf0 !important;
}

.rbc-time-content {
  border-top: 2px solid #ebebf0;
}

.bg-red-500 {
  background-color: red !important;
}

.rbc-events-container {
  margin-right: 0px !important;
}

.boxDataInfoWeek {
  font-size: 10px;
  height: 90px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.gridItemInfoWeek {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 3px;
  color: #3d3e46 !important;
  font-weight: bold;
}

.tittleGridItemInfoWeek {
  display: block;
  font-weight: bold;
}

.fontBold {
  font-weight: bold;
}

.rbc-toolbar-label {
  color: #00b4cc;
  text-transform: uppercase;
  font-weight: bold;
  order: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rbc-toolbar {
  justify-content: space-between !important;
}

.rbc-allday-cell {
  display: none;
}

.rbc-time-column:first-child {
  background-color: white !important;
}

.rbc-timeslot-group {
  min-height: 60px !important;
  /* min-height: 250px !important; */
  display: flex !important;
  align-items: flex-end !important;
}

.rbc-month-header {
  color: #00b4cc;
  border-bottom: 1px solid #ccc !important;
  /* margin-bottom: 10px */
}

.rbc-header {
  padding: 10px !important;
  border-bottom: none !important;
}

.rbc-header + .rbc-header {
  border: none !important;
}

.rbc-date-cell {
  color: #b2adad !important;
  font-weight: bold;
}

.rbc-event-label {
  display: none !important;
}

/* .rbc-button-link {
  color: #00B4CC !important;
  font-weight: bold !important;
} */

/* .rbc-date-cell .rbc-button-link {
  color: #b2adad !important;
  font-weight: bold !important;
} */

.rbc-month-row {
  /* border: 1px solid #ddd */
  border: none;
}

.rbc-off-range-bg {
  background-color: rgb(246, 254, 255) !important;
}

.rbc-off-range .rbc-button-link {
  color: #d6e0eb !important;
}

.rbc-date-cell {
  color: #6e6f7c !important;
}

.rbc-time-content {
  border-top: 0px !important;
}

.rbc-time-header {
  border-bottom: 1px solid #ddd;
}

.rbc-header {
  color: #6e6f7c !important;
}

.closeModal {
  position: absolute;
  height: 50px;
  width: 50px;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #8d8d8d;
  cursor: pointer;
}

.css-o4b71y-MuiAccordionSummary-content {
  order: 2;
  align-items: center;
  column-gap: 20px;
  margin: 0px !important;
  color: #697387;
}

.css-1elwnq4-MuiPaper-root-MuiAccordion-root {
  background-color: transparent !important;
}

.css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root {
  column-gap: 20px;
  padding-bottom: 0px !important;
}

.css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root {
  padding-top: 19 px !important;
}

.css-yw020d-MuiAccordionSummary-expandIconWrapper {
  color: #00b4cc !important;
}

.rbc-day-slot .rbc-event,
.rbc-day-slot .rbc-background-event {
  min-height: 10px !important;
  border-bottom: 2px solid white !important;
}

.rbc-time-header.rbc-overflowing {
  margin-bottom: 10px !important;
}

.w80 {
  width: 80% !important;
}

.rem8 {
  padding-left: 8rem !important;
}

/* .acordionHeader {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 2fr 1fr 1fr 1fr 1fr 1fr 1.5fr;
} */

.acordionHeader {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1.6fr 1fr 1fr 1.4fr 1fr 1fr 1.5fr;
  align-items: center;
}

.acordionHeader2 {
  display: grid;
  grid-template-columns: 10% 1fr 1fr 2fr 1fr 1fr 1fr 1fr 1fr 1.5fr;
  align-items: end;
}

.a {
  display: grid;
  grid-template-columns: 40.3% 45.3% 13.3%;
  width: 100%;
}

.b {
  display: grid;
  grid-template-columns: 43.3% 43.3% 13.3%;
  align-items: end;
}

@media (max-width: 1024px) {
  .acordionHeader2 {
    grid-template-columns: 11% 1fr 1fr 2fr 1fr 1fr 1fr 1fr 1fr 1.5fr;
  }
}

.acordionHeader2 > tr > th:first-child {
  margin-left: 15px;
}

.acordionHeader td:last-child {
  padding: 0px;
}

.acordionHeader td:last-child > div {
  margin-left: 0px !important;
}

.css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root {
  gap: 0;
  padding: 0px !important;
}

.css-pwcg7p-MuiCollapse-root,
.css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded {
  padding-bottom: 0px;
  padding-top: 0px;
  transition: all 0.5s;
}

.css-15v22id-MuiAccordionDetails-root {
  padding: 0px !important;
}

.css-1elwnq4-MuiPaper-root-MuiAccordion-root:hover {
  background-color: #d2dde8 !important;
  transition: all 0.5s;
}

.css-pwcg7p-MuiCollapse-root:hover {
  background-color: white !important;
  transition: all 0.5s;
}

.table > :not(caption) > * > * {
  padding: 0px;
}

.css-15v22id-MuiAccordionDetails-root {
  padding-left: 60px !important;
  color: #697387;
}

.css-yw020d-MuiAccordionSummary-expandIconWrapper {
  color: #00b4cc !important;
}

.input-wrapper {
  position: relative;
}

.input-icon {
  color: #191919;
  position: absolute;
  width: 20px;
  height: 20px;
  left: 85%;
  top: 70%;
  transform: translateY(-50%);
}

.input-icon-large {
  color: #191919;
  position: absolute;
  width: 20px;
  height: 20px;
  left: 93%;
  top: 70%;
  transform: translateY(-50%);
}

.react-datepicker-wrapper {
  display: flex !important;
  align-items: end;
}

.css-yw020d-MuiAccordionSummary-expandIconWrapper {
  padding: 0px 0px 0px 10px;
}

table.table > :not(:last-child) > :last-child > * {
  font-size: 13px;
  border-bottom: 1px solid #ccc;
}

.css-o4b71y-MuiAccordionSummary-content {
  display: block !important;
}

.app_titleModalCalendar {
  text-align: start;
  /* margin-left: 15px; */
  /* width: 80%; */
  font-size: 20px !important;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.rbc-event:focus,
.rbc-day-slot .rbc-background-event:focus {
  outline: none !important;
}

.rbc-day-bg .rbc-today {
  background: white;
}

.css-yw020d-MuiAccordionSummary-expandIconWrapper {
  color: #00b4cc !important;
}

.rbc-day-slot .rbc-event,
.rbc-day-slot .rbc-background-event {
  min-height: 10px !important;
  border-bottom: 2px solid white !important;
}

.rbc-time-header.rbc-overflowing {
  margin-bottom: 10px !important;
}

.hoverB:hover {
  opacity: 0.6;
}

.css-jjmvzj-control {
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

.textCustom {
  border: 1px solid #a3bad1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  background: #f5f7fa;
}

.pointer {
  cursor: pointer;
}

.hoverable:hover {
  color: #01a0f6;
}

.react-datepicker-wrapper {
  width: 250px !important;
  margin-left: auto !important;
  margin-right: 5px;
}

.react-datepicker__input-container {
  display: flex !important;
  justify-content: end !important;
}

#xx:hover img {
  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg)
    brightness(103%) contrast(106%);
}

.bgBlueLight {
  background-color: #a3e4ec24;
}

.css-1480iag-MuiInputBase-root-MuiInput-root {
  color: #003f80 !important;
}

.wpx30 {
  width: 30px !important;
}

.prueba {
  background-image: url('./assets/img/legalPersonColor.png');
}

.hideFile {
  display: none;
}

.file-select {
  position: relative;
  display: inline-block;
  margin-top: 10px;
  color: #003f80;
  cursor: pointer;
}

.file-select::before {
  border: 2px solid #003f80;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  content: 'Imagen cargada';
  width: 200px;
  /* testo por defecto */
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 15px;
  cursor: pointer;
}

.file-select input[type='file'] {
  opacity: 0;
  width: 200px;
  height: 32px;
  display: inline-block;
  cursor: pointer;
}

#src-file1::before {
  content: 'Cargar imagen';
}

#src-file2::before {
  content: 'Cargar imagen';
}

.file-select:hover {
  background-color: #003f80;
  color: white !important;
  cursor: pointer;
}

.ordAquaMarine {
  background-color: #00b4cc !important;
}

.timesPlace::placeholder {
  color: #ccc;
  font-size: 11px;
}

.timesPlace {
  color: #6e6f7c !important;
}

.focusBlueVisible:focus-visible {
  outline: 1px solid #005dbf !important;
}

.registerInputs2 {
  border: 1.5px solid #a3bad1;
  border-radius: 5px;
  width: 100%;
  padding: 0 0.5rem;
  height: 32px;
  font-size: 13px;
  color: #58595b;
}

.registerInputs2:focus-visible {
  border: 2px solid #01a2f5 !important;
  outline: none;
}

.input-wrapper2 {
  position: relative;
}

.input2 {
  box-sizing: border-box;
  width: 100%;
}

.input.password2 {
  padding: 15px 35px 15px 15px;
}

.input-icon2 {
  color: #191919;
  position: absolute;
  width: 20px;
  height: 20px;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
}

.input-icon.password2 {
  left: unset;
  right: 12px;
}

.focusBlueVisible:focus-visible {
  outline: 1px solid #005dbf !important;
}

.w100 {
  width: 100px;
  text-align: center;
}

.fontFamilyTable {
  font-family: 'PT Sans' !important;
  line-height: 19.41px;
  size: 15px;
  font-weight: 700;
}

.hoverImg:hover {
  background-color: #005dbf33 !important;
  border-radius: 100%;
}

.textGrayPlaceholder::placeholder {
  color: hsl(0, 0%, 50%) !important;
}

.textGrayPlaceholder2::placeholder {
  color: #58595b66 !important;
}

.focusBlueVisible:focus-visible {
  outline: 1px solid #005dbf !important;
}

.w100 {
  width: 100px;
  text-align: center;
}

.fontFamilyTable {
  font-family: 'PT Sans' !important;
  line-height: 19.41px;
  size: 15px;
  font-weight: 700;
}

.hoverImg:hover {
  background-color: #005dbf33 !important;
  border-radius: 100%;
}

.textGrayPlaceholder::placeholder {
  color: hsl(0, 0%, 50%) !important;
}

.textGrayPlaceholder2::placeholder {
  color: #58595b66 !important;
}

.w210 {
  width: 210px !important;
}

input {
  outline: none;
}

.pl1 {
  padding-left: 0.25rem !important;
}

.none {
  display: none;
}

.block {
  display: block;
}

input[type='date']::-webkit-calendar-picker-indicator {
  cursor: pointer;
  border-radius: 4px;
  margin-right: 2px;
  opacity: 0.9;
  filter: brightness(0) saturate(100%) invert(76%) sepia(6%) saturate(3401%) hue-rotate(181deg)
    brightness(87%) contrast(100%);
}

input[type='time']::-webkit-calendar-picker-indicator {
  cursor: pointer;
  border-radius: 4px;
  margin-right: 2px;
  opacity: 0.9;
  filter: brightness(0) saturate(100%) invert(97%) sepia(16%) saturate(5734%) hue-rotate(174deg)
    brightness(89%) contrast(82%);
}

/* regular text style */
input[type='date'].colorDateModuleAdmision::-webkit-datetime-edit-text,
input[type='date'].colorDateModuleAdmision::-webkit-datetime-edit-month-field,
input[type='date'].colorDateModuleAdmision::-webkit-datetime-edit-day-field,
input[type='date'].colorDateModuleAdmision::-webkit-datetime-edit-year-field {
  color: #a3bad1;
}

input[type='date'].colorDateModuleAdmisionActive::-webkit-datetime-edit-text,
input[type='date'].colorDateModuleAdmisionActive::-webkit-datetime-edit-month-field,
input[type='date'].colorDateModuleAdmisionActive::-webkit-datetime-edit-day-field,
input[type='date'].colorDateModuleAdmisionActive::-webkit-datetime-edit-year-field {
  color: #1a538d;
}

.pl1 {
  padding-left: 0.25rem !important;
}

input {
  outline: none;
}

/* input[type="date"]{
  font-weight: bold !important;
} */

.asistencialDateColorIcon input[type='date']::-webkit-calendar-picker-indicator {
  cursor: pointer;
  border-radius: 4px;
  margin-right: 8px;
  opacity: 0.9;
  /* filter: brightness(0) saturate(50%) invert(13%) sepia(42%) saturate(6966%) hue-rotate(200deg) brightness(92%) contrast(101%); */
  filter: brightness(0) saturate(100%) invert(97%) sepia(16%) saturate(5734%) hue-rotate(174deg)
    brightness(89%) contrast(82%);
}

.btnCancelAdmin {
  transition: all 0.3s;
  color: #005dbf;
  width: 192px;
  padding: 10px;
  border: 1px solid #005dbf;
  font-weight: bold;
  text-align: center;
  border-radius: 5px;
}

.btnSaveAdmin {
  transition: all 0.3s;
  background-color: #005dbf;
  color: white;
  width: 192px;
  padding: 10px;
  border: 1px solid #005dbf;
  font-weight: bold;
  text-align: center;
  border-radius: 5px;
}

.btnSaveAdmin:hover {
  opacity: 0.6;
}

.btnCancelAdmin:hover {
  background-color: #005dbf;
  color: white;
}

.country-option {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.selected-logo,
.country-logo {
  width: 35px;
  height: 20px;
  filter: brightness(0) saturate(100%) invert(52%) sepia(84%) saturate(2782%) hue-rotate(171deg)
    brightness(94%) contrast(107%);
}

@keyframes append-animate {
  from {
    transform: scaleY(0);
    opacity: 0;
  }

  to {
    transform: scaleY(1);
    opacity: 1;
  }
}

.new-box {
  transform-origin: 50% 0;
  animation: append-animate 0.3s linear;
}

label + .css-1480iag-MuiInputBase-root-MuiInput-root {
  align-items: baseline;
}

.form-control:focus {
  border-color: #a3bad1 !important;
  box-shadow: none !important;
}

.ck-rounded-corners .ck.ck-editor__main > .ck-editor__editable,
.ck.ck-editor__main > .ck-editor__editable.ck-rounded-corners {
  min-height: 100px !important;
}

#opportunityInputDate .react-datepicker-wrapper div {
  width: auto;
  margin-left: 4px;
  height: 31px;
}

.rec .rec-swipable {
  align-items: center !important;
}

#new-style-react-date .react-datepicker__current-month {
  text-transform: capitalize !important;
  margin-block: 5px;
}

#new-style-react-date .react-datepicker {
  width: 100%;
  border: 1px solid #00b4cc !important;
  border-radius: 10px;
}

#new-style-react-date .react-datepicker__day-name {
  color: #6e6f7c !important;
}

#new-style-react-date .react-datepicker__day {
  color: #6e6f7c !important;
}

#new-style-react-date .react-datepicker__current-month {
  color: #6e6f7c !important;
}

#new-style-react-date .react-datepicker__header {
  background: #fff !important;
  border-bottom: 0;
  padding-bottom: 0;
  border-radius: 10px;
}

#new-style-react-date .react-datepicker__month-container {
  width: 100%;
  border-radius: 2rem !important;
}

#new-style-react-date .react-datepicker__week {
  display: flex;
  justify-content: space-around;
}

#new-style-react-date .react-datepicker__navigation {
  height: 25px !important;
  width: 25px !important;
  top: 10px !important;
  right: 10px !important;
}

#new-style-react-date .react-datepicker__navigation-icon--next {
  position: relative !important;
  filter: brightness(0) saturate(100%) invert(69%) sepia(30%) saturate(7409%) hue-rotate(146deg)
    brightness(91%) contrast(101%) !important;
}

#new-style-react-date .react-datepicker__navigation-icon--next {
  left: 0px !important;
}

#new-style-react-date .react-datepicker__navigation-icon {
  top: 3px !important;
}

#new-style-react-date .react-datepicker__navigation--previous {
  left: 10px !important;
}

#new-style-react-date .react-datepicker__navigation-icon--previous {
  right: 0px !important;
  filter: brightness(0) saturate(100%) invert(69%) sepia(30%) saturate(7409%) hue-rotate(146deg)
    brightness(91%) contrast(101%) !important;
}

#new-style-react-date .react-datepicker__day--keyboard-selected {
  background-color: transparent !important;
}

#new-style-react-date .react-datepicker__day:hover {
  background-color: #e6f8fa;
  border: 1px solid #00b4cc !important;
  transition: all 0.1s;
}

#new-style-react-date .react-datepicker__day {
  border: 1px solid transparent !important;
  transition: all 0.1s;
}

#new-style-react-date .react-datepicker__day--disabled {
  color: #ccc !important;
}

#new-style-react-date .react-datepicker__day--disabled:hover {
  border: transparent !important;
  background-color: transparent !important;
}

#new-style-react-date .react-datepicker__day-names {
  text-transform: capitalize !important;
  font-size: 12px !important;
  display: flex;
  justify-content: space-around;
  margin-inline: 0.4rem;
}

#new-style-react-date .react-datepicker__day--highlighted {
  background-color: #fff !important;
  color: #00b4cc !important;
}
#new-style-react-date .react-datepicker__day--highlighted.danger {
  color: #f39682 !important;
}

#new-style-react-date .react-datepicker__day--selected {
  background-color: #00b4cc !important;
  color: #fff !important;
}

.styleSearchCite {
  height: 36px;
  margin-top: auto;
  border-radius: 5px;
  transition: all 0.2s;
  cursor: pointer;
}

.styleSearchCiteInactive {
  height: 36px;
  margin-top: auto;
  border-radius: 5px;
  transition: all 0.2s;
  filter: grayscale(1);
}

.styleSearchCite:hover {
  background-color: #00b4cc;
}

.styleSearchCite:hover img {
  filter: brightness(0) saturate(100%) invert(100%) sepia(10%) saturate(7498%) hue-rotate(296deg)
    brightness(112%) contrast(117%);
}

.accordionStyles > td {
  border-color: #f5f7fa !important;
  background: #f5f7fa !important;
}

.accordionStyles > tr > td:hover {
  background: red !important;
}

#tdWhite:hover > td {
  background: white !important;
}

.tableNewStyle > td,
#dd > th {
  border-bottom: 1px solid #d6e0eb !important;
}

.tableScroll {
  max-height: 230px;
  overflow-y: scroll;
}

.tableScroll table {
  width: 100%;
  border-collapse: collapse;
}

.tableScroll th {
  background-color: #d6f3f7;
  position: sticky;
  top: 0;
  z-index: 1;
}

.swal2-content {
  padding: 0px !important;
}

.w-92 {
  width: 92% !important;
}

#calendarHolidays .rbc-toolbar span:nth-child(1) button:nth-child(1),
#calendarHolidays .rbc-toolbar span:nth-child(3) button:nth-child(1),
#calendarHolidays .rbc-toolbar span:nth-child(3) button:nth-child(2),
#calendarHolidays .rbc-toolbar span:nth-child(3) button:nth-child(3),
#calendarHolidays .rbc-toolbar-label {
  color: #005dbf !important;
}
