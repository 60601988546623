@font-face {
  font-family: "PT Sans Caption";
  src: url("../../../../assets/fonts/PT_Sans_Caption/PTSansCaption-Regular.ttf")
    format("truetype");
}

@font-face {
  font-family: "PT Sans Caption";
  src: url("../../../../assets/fonts/PT_Sans_Caption/PTSansCaption-Regular.ttf")
    format("truetype");
}

.table {
  margin: 0px;
  table-layout: fixed;
  font-family: "PT Sans Caption";
  font-weight: 400;
  font-style: normal;
  width: 100%;
  text-align: center;
  border-spacing: 0;
}

.table td,
.table th {
  padding: 0.5rem 1rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.table thead th {
  padding: 3px;
  position: sticky;
  top: 0;
  z-index: 1;
  width: 15vw;
  background: #005dbf;
}

.table td {
  background: #fff;
  padding: 4px 5px;
  text-align: center;
  border-top: 0px;
  border-right: 0px;
  border-bottom: 0px;
  border-left: 0px;
}

.table tbody th {
  font-weight: 100;
  font-style: italic;
  text-align: left;
  position: relative;
}

/* Fijar la primera columna */
.table thead th:first-child {
  position: sticky;
  left: 0;
  z-index: 3;
  background: #005dbf;
}

/* Fijar la segunda columna */
.table thead th:nth-child(2) {
  position: sticky;
  left: 15vw; /* Ajusta este valor dependiendo del ancho de la primera columna */
  z-index: 3;
  background: #005dbf;
}

/* Fijar la primera columna */
.table tbody th:first-child,
.table tbody td:first-child {
  position: sticky;
  left: 0;
  z-index: 2;
  /* background: #005dbf; */
}

/* Fijar la segunda columna */
.table tbody th:nth-child(2),
.table tbody td:nth-child(2) {
  position: sticky;
  left: 15vw; /* Ajusta este valor dependiendo del ancho de la primera columna */
  z-index: 2;
}

.table tbody tr:last-child {
  text-align: left;
  padding: 0.25rem;
  position: sticky;
  left: 0;
  bottom: -1px;
  z-index: 4;
  font-weight: bold;
}

.table tbody tr:last-child td {
  background-color: white;
  z-index: 4;
  font-weight: bold;
}

.role {
  width: 100%;
  max-height: 55vh;
  overflow: auto;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.role:focus {
  outline: 0;
}

.role::-webkit-scrollbar {
  -webkit-appearance: none;
}

.role::-webkit-scrollbar:vertical {
  width:0px;
}

.role::-webkit-scrollbar-button:increment,.role::-webkit-scrollbar-button {
  display: none;
}

.role::-webkit-scrollbar:horizontal {
  height: 10.5px;
}

.role::-webkit-scrollbar-thumb {
  background-color: #aaaaaa;
  border-radius: 20px;
  border: 2px solid #f1f2f3;
}

.role::-webkit-scrollbar-track {
  border-radius: 10px;
}