
.filterImage{
    filter: brightness(0) saturate(100%) invert(23%) sepia(82%) saturate(2001%) hue-rotate(197deg);
    cursor: pointer;
}

.textareaStyle {
    border: 1px solid #7FADDE;
    border-radius: 5px;
    width: 100%;
    padding: 0 0.5rem;
    font-size: 12px;
    outline: none;
    min-height: 3.5rem;
    color: #6c757d;
}