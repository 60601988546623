@import "../../styleConstants.scss";
@font-face {
  font-family: "PT Sans Caption";
  src: url("../../assets/fonts/PT_Sans_Caption/PTSansCaption-Regular.ttf")
    format("truetype");
}

@font-face {
  font-family: "PT Sans Caption";
  src: url("../../assets/fonts/PT_Sans_Caption/PTSansCaption-Regular.ttf")
    format("truetype");
}

.genericTable {
  font-family: "PT Sans Caption";
  font-weight: 400;
  font-style: normal;
  width: 100%;
  margin-top: 1rem;
  text-align: center;
  border-spacing: 0;
}

.genericTable > tbody > tr > td {
  padding: 0.4rem 0;
  text-transform: lowercase;
  background-color: $white;
  color: $tableText;
  font-size: 13px;
  line-height: 16.8px;
  font-weight: 500;
  vertical-align: middle;
  border-bottom: 1px solid #9bdee7;
}
.genericTable > tbody > tr > td::first-letter {
  text-transform: capitalize;
}

// .genericTable> tbody> tr:nth-of-type(even)>td {
//     background-color: $tableEven;
// }

.genericTable > tbody > tr:hover > td {
  background-color: $tableHover;
  transition: 0.3s all ease-in-out;
}

.genericTable > tbody > tr:last-child > td:first-child {
  border-bottom-left-radius: 10px;
}
.genericTable > tbody > tr:last-child > td:last-child {
  border-bottom-right-radius: 10px;
}

.thead > tr > th {
  font-size: 15px;
  line-height: 19.4px;
  padding: 0.5rem 0;
}

.theadLight {
  color: $ordTableTextColor;
  background-color: $ordTableHeadBgColor;
  border-bottom-width: 0px !important;
}

.theadDark {
  color: $white;
  background-color: $darkBlue;
}
.theadBottomless {
  color: $darkBlue;
}

.thead > tr > th:first-child {
  border-top-left-radius: 10px;
}
.thead > tr > th:last-child {
  border-top-right-radius: 10px;
}

.genericTable > tbody > tr.orange > td {
  background-color: $tableAddition;
}

.genericTable > tbody > tr.orange:hover > td {
  background-color: $tableAdditionHover;
  transition: 0.3s all ease-in-out;
}

// FOR TABLE TO NEED RECORDS IN LOWER CASE
.genericTableLower {
  font-family: "PT Sans Caption";
  font-weight: 400;
  font-style: normal;
  width: 100%;
  margin-top: 1rem;
  text-align: left;
  border-spacing: 0;
}

.genericTableLower > tbody > tr > td {
  border: none;
  padding: 6.5px;
  background-color: $white;
  color: $tableText;
  font-size: 13px;
  line-height: 16.8px;
  font-weight: 500;
  vertical-align: middle;
}
.genericTableLower > tfoot > tr > td {
  border: none;
  padding: 4px;
  background-color: $white;
  color: $tableText;
  font-size: 13px;
  line-height: 16.8px;
  font-weight: 500;
  vertical-align: middle;
}

.genericTableLower > tbody > tr:nth-of-type(even) > td {
  background-color: $tableEven;
}

.genericTableLower > tfoot > tr:nth-of-type(odd) > td {
  background-color: $tableEven;
}

.genericTableLower > tbody > tr:hover > td {
  background-color: $tableHover;
  transition: 0.3s all ease-in-out;
}

.genericTableLower > tbody > tr:last-child > td:first-child {
  border-bottom-left-radius: 10px;
}
.genericTableLower > tfoot > tr:last-child > td:last-child {
  border-bottom-right-radius: 10px;
}

.genericTableLower > tbody > tr.orange > td {
  background-color: $tableAddition;
}

.genericTableLower > tbody > tr.orange:hover > td {
  background-color: $tableAdditionHover;
  transition: 0.3s all ease-in-out;
}

.genericTableLower > tfoot > tr:hover > td {
  background-color: $tableHover;
  transition: 0.3s all ease-in-out;
}

.genericTableLower > tfoot > tr:last-child > td:first-child {
  border-bottom-left-radius: 10px;
}

.genericTableLower > tfoot > tr.orange > td {
  background-color: $tableAddition;
}

.genericTableLower > tfoot > tr.orange:hover > td {
  background-color: $tableAdditionHover;
  transition: 0.3s all ease-in-out;
}

.genericTableLower > tfoot > tr > td {
  border: none;
  padding: 4px;
  background-color: $white;
  color: $tableText;
  font-size: 13px;
  line-height: 16.8px;
  font-weight: 500;
  vertical-align: middle;
}

.genericTableLower > tfoot > tr:nth-of-type(odd) > td {
  background-color: $tableEven;
}

.genericTableLower > tfoot > tr:last-child > td:last-child {
  border-bottom-right-radius: 10px;
}

.genericTableLower > tfoot > tr:hover > td {
  background-color: $tableHover;
  transition: 0.3s all ease-in-out;
}

.genericTableLower > tfoot > tr:last-child > td:first-child {
  border-bottom-left-radius: 10px;
}
.genericTableLower > tfoot > tr:last-child > td:last-child {
  border-bottom-right-radius: 10px;
}

.genericTableLower > tfoot > tr.orange > td {
  background-color: $tableAddition;
}

.genericTableLower > tfoot > tr.orange:hover > td {
  background-color: $tableAdditionHover;
  transition: 0.3s all ease-in-out;
}
.oneHeadDarkBlue .thead > tr > th:first-child {
  background-color: $darkBlue;
  color: $white;
  padding: 0.5rem 1rem;
}
.treeHeadDarkBlue .thead > tr > th:nth-child(1n + 5) {
  background-color: $darkBlue;
  color: $white;
  padding: 0.5rem 1rem;
}

.firstdClearBlue > tr > td:first-child {
  padding: 0.4rem 0;
  text-transform: lowercase;
  background-color: $ordTableHeadBgColor;
  color: $tableText;
  font-size: 13px;
  line-height: 16.8px;
  font-weight: 500;
  vertical-align: middle;
  border-bottom: 1px solid #9bdee7;
}

.backgroundImage {
  padding-left: 5PX;
  border-radius: 5px;
  border: 0px;
  background-image: url("../../assets/img/icons/Filtrar.svg");
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-color: #F5F7FA;
  height: 28px;
  color:#6E6F7C;
}

.ordCard{
  border: 1px solid #CCD9E6;
  box-sizing: border-box;
  box-shadow: 0px 0px 6px 0px #13131333;
  border-radius: 10px;
  padding: 16px 24px;
  color: #6E6F7C
}

.ordAccordion{
  border-radius: 10px;
  box-shadow: 0px 0px 6px 0px #13131333
}

.ordBox{
  border: 1px solid #CCD9E6;
  box-sizing: border-box;
  box-shadow: 0px 0px 16px rgba(19, 19, 19, 0.2);
  border-radius: 15px;
  padding: 10px 15px;
  color: #00B4CC;
  height: 120px;
  width: 192px;
  text-align: center;
  align-items: center;
}
.ordBoxImg{
  height: 26px;
  width: 26px;
}
.OrdSlideButton{
  background: #F7FEFF;
  box-shadow: 0px 0px 6px rgba(19, 19, 19, 0.2);
  border-radius: 10px;
  margin-top: 16px;
  margin-bottom: 20px;
}

.OrdSlideButton2{
  background: #D6F3F7;
  box-shadow: 0px 0px 6px rgba(19, 19, 19, 0.2);
  border-radius: 10px;
  margin-top: 16px;
  margin-bottom: 20px;
}