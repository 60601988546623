@import "../../styleConstants.scss";

.name-day {
    text-align: center;
    background-color: #F5F7FA;
    width: 120px;
}

.name-day>p {
    padding: 10px;
    margin: 0;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 19px;
    color: #58595B;
}

.number-day {
    border: 1px solid #CECECE;
    width: 120px;
    height: 97px;
    padding: 12px;
}

.text-day {
    font-family: PT Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 31px;
    color: #58595B;

}

.day-icons {
    background-color: #005DBF;
    width: 74px;
    height: 26px;
    border-radius: 6px;
    margin: auto 23px;
}

.container-nameday {
    filter: drop-shadow(0px 1px 4px rgba(0, 63, 128, 0.35));
}

.card-principal {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;

    label {
        font-size: 18px;
        font-weight: bold;
        color: #FFFFFF;
    }

    p {
        font-family: PT Sans;
        font-style: normal;
        font-size: 15px;
        color: #FFFFFF;
    }
}

.border-muted {
    border-right: 2px solid rgba(#FFF, $alpha: 0.4);
}

.card-segundario {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    article {
        text-align: center;

        p {
            margin: 0;
        }

        p:first-child {
            font-weight: bold;
            font-size: 15px;
            width: max-content;
            color: #58595B;
            text-align: center;
        }
    }
}

.card-divwhite {
    border-left: 1px solid #FFFFFF;
    width: 1px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.card-divgray {
    border-left: 1px solid #CECECE;
    width: 1px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.card-container {
    border-radius: 15px 0px 0px 15px;
    background-color: #01A0F6;
}

.card-container-2 {
    height: 100%;
    width: 100%;
}

.card-container-principal {
    border-radius: 15px 15px 15px 15px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
}

@media only screen and (max-width: 1000px) {
    .card-mediaQ {
        width: 490px !important;
    }

    .card-mediaQ2 {
        width: 42vw !important;
    }
}

@media only screen and (max-width: 1400px) {
    .card-mediaQ {
        width: 56vw;
    }

    .card-mediaQ2 {
        width: 42vw !important;
    }
}

.card-mediaQ {
    ::-webkit-scrollbar {
        height: 6px;
    }

}

.card-mediaQ2 {
    ::-webkit-scrollbar {
        height: 6px;
    }

    width: max-content;
}






*,
*:before,
*:after {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

:root {
    --color-bg-calendar: #1b1e22;
    --color-cell: #e3e3e3;
    --color-cell-disabled: #424649;
}

.root {
    min-height: 100vh;
    padding: 0 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.calendar {
    padding: 20px 10px;
    background-color: var(--color-bg-calendar);
    border-radius: 3px;
    width: 100%;
    max-width: 300px;
    font-family: sans-serif;
}

.calendar__header {
    color: white;
    display: flex;
    justify-content: space-between;
    text-transform: uppercase;
    margin-bottom: 30px;
}

.calendar .control {
    color: white;
    font-weight: bold;
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 0 5px;
}

.grid__header,
.grid__body {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
}

.grid__header {
    color: white;
    margin-bottom: 20px;
}

.grid__body {
    grid-auto-rows: 40px;
    color: var(--color-cell);
}

.grid__cell {
    display: flex;
    justify-content: center;
    align-items: center;
}

.grid__cell--gd {
    cursor: pointer;
}

.grid__cell--selected {
    color: var(--color-bg-calendar);
    background-color: white;
    border-radius: 50%;
    border: 2px solid white;
    box-shadow: 0 0 0 2px var(--color-bg-calendar) inset;
}

.grid__cell--disabled {
    color: var(--color-cell-disabled);
    cursor: not-allowed;
}

.viewMora {
    color: #58595B;
    font-weight: bold;
    font-size: 14px;
    margin-top: 5px;
    width: 90px;
    height: 25px;
    border-radius: 10px;
    margin-right: 10px;
}

.viewMora:hover {
    background-color: #005DBF1A;
    ;
}

.viewProvi {
    color: #58595B;
    font-weight: bold;
    font-size: 14px;
    margin-top: 5px;
    width: 110px;
    height: 25px;
    border-radius: 10px;
}

.viewProvi:hover {
    background-color: #005DBF1A;
    ;
}