@import "../../styleConstants.scss";
$circleTimeLine: url("../../assets/img/icons/circleTimeline.svg");

.genericTable {
  padding: 0 1.5rem;
  top: 3rem;
  font-family: futura-pt, sans-serif;
  font-weight: 400;
  font-style: normal;
  border-collapse: collapse;
  width: 100%;
  max-width: 100%;
  border-radius: 15px;
  margin-top: 1rem;
  text-align: center;
  border-spacing: 0;
  border: 1px solid $clearBlueBorder;
}

.greenCheck {
  background-image: url("../../assets/img/icons/CheckMark.svg");
  background-repeat: no-repeat;
  width: 1.5rem;
  height: 1.1rem;
  width: "-webkit-fill-available";
  margin: auto;
}

.alertTriangle {
  background-image: url("../../assets/img/icons/Alert.svg");
  background-repeat: no-repeat;
  width: "-webkit-fill-available";
  width: 1.5rem;
  height: 1.1rem;
  margin: auto;
}

.genericTable>tbody>tr>td {
  border: none;
  padding: 0.4rem 0;
  text-transform: capitalize;
}

.genericTable>tbody>tr {
  background-color: $white;
}

.genericTable>tbody>tr:nth-of-type(even) {
  background-color: $tableEven;
}

.genericTable>tbody>tr:hover {
  background-color: $tableHover;
  transition: 0.3s all ease-in-out;
}

.genericTable>tbody>tr>td {
  text-align: left;
  font-size: 0.8rem;
  font-weight: 500;
  vertical-align: middle;
}

.genericTable>tbody>tr>td:first-child {
  padding-left: 2.5rem;
}

.theadLight {
  color: $white;
  background-color: $darkBlue;
}

.theadLight>tr>th {
  padding: 0.5rem 0;
}

.groupElems {
  // max-width: 7rem;
  display: flex;
  margin-right: 0;
  margin-left: auto;
  justify-content: space-between;
  vertical-align: middle;
}

.groupElemsWithoutMarginL {
  max-width: 5rem;
  display: flex;
  margin-right: 0;
  justify-content: space-between;
  vertical-align: middle;
}

.iconWrap {
  display: flex;
  margin-right: 0;
  margin-left: auto;
  justify-content: space-between;
  vertical-align: bottom;
  border-radius: 6px;
  padding: 4px 7px;
  align-items: end;
  cursor: pointer;
  height: 24px;
}

.iconWrap:hover {
  background-color: rgba(0, 93, 191, 0.05);
  height: 24px;
}

.iconWrap:hover>.iconLabel {
  color: $darkBlue;
}

.iconWrap>img {
  width: 1rem;
  margin: 0 0.3rem;
}

.iconWrap:hover>img {
  filter: brightness(0) saturate(100%) invert(17%) sepia(68%) saturate(4437%) hue-rotate(201deg) brightness(98%) contrast(100%);
}

.iconLabel {
  font-family: "PT Sans Caption";
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  color: #58595b;
}

.iconSvg {
  width: 1.7rem;
  margin: 0 0.5rem;
  cursor: pointer;
}

.iconSvgSmall {
  width: 1rem;
  margin: 0 0.3rem;
}

.iconSvgSemi {
  width: 2rem;
  margin: 0 0.5rem;
}

.iconSvgMedium {
  width: 3.5rem;
  margin: 0;
}

.iconSvgMargin {
  margin: 0 0.5rem;
}

.iconSvgMargin2 {
  margin-bottom: 2px;
  margin-left: 5px;
}

.iconSvg:hover,
.iconSvgSemi:hover,
.iconSvgSmall:hover,
.iconSvgMedium:hover {
  cursor: pointer;
  opacity: 0.8;
}

.iconsGear {
  width: 2rem;
  margin: 0 0.5rem;
}

.circleGreen {
  background: $greenEnabled;
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  margin: auto;
}

.circleRed {
  background: $red;
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  margin: auto;
}

.baseStatus {
  border-radius: 10px;
  padding: 5px 10px;
  margin: auto;
  font-weight: bold;
  text-transform: capitalize;
  text-align: center;
  font-size: 12px;
  white-space: nowrap;
}

.greenState {
  background: #E6F6EE;
  border-radius: 10px;
  padding: 5px 10px;
  color: #00A551;
  margin: auto;
  font-weight: bold;
  text-transform: capitalize;
  width: 110px;
  text-align: center;
}

.lightGreenState {
  background: #FAFDF6;
  border-radius: 10px;
  padding: 5px 10px;
  color: #83C036;
  margin: auto;
  text-transform: capitalize;
  width: 100px;
  text-align: center;
}

.lightRedState {
  text-align: center;
  background: #fefaf5;
  border-radius: 10px;
  padding: 5px 10px;
  color: #f39682;
  margin: auto;
  text-transform: capitalize;
  width: 100px;
}

.stateExpired {
  background: #E8E8EA;
  border-radius: 10px;
  padding: 5px 10px;
  color: #6E6F7C;
  margin: auto;
  font-weight: bold;
  text-transform: capitalize;
  width: 100px;
  text-align: center;
}

.greenState2 {
  background: #E6F6EE;
  border-radius: 10px;
  padding: 5px 10px;
  color: #00A551;
  margin: auto;
  font-weight: bold;
  text-transform: capitalize;
  width: 120px;
  text-align: center;
}

.yellowState {
  background: #FEFAEE;
  border-radius: 10px;
  padding: 5px 10px;
  color: #f2c94c;
  margin: auto;
  font-weight: bold;
  text-transform: capitalize;
  width: 100px;
  text-align: center;
}

.redState {
  text-align: center;
  background: #FEE9EA;
  border-radius: 10px;
  padding: 5px 10px;
  color: #EC1C24;
  margin: auto;
  font-weight: bold;
  text-transform: capitalize;
  width: 110px;
  //margin: auto .5rem;
}

.redState2 {
  text-align: center;
  background: #FEE9EA;
  border-radius: 10px;
  padding: 5px 10px;
  color: #EC1C24;
  margin: auto;
  font-weight: bold;
  text-transform: capitalize;
  width: 120px;
  //margin: auto .5rem;
}

.circleYellow {
  background: #f2c94c;
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  margin: auto;
}

.circleBlack {
  background: #58595b;
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  margin: auto;
}

.cursorFlecha {
  cursor: default;
}

.cursorPointer:hover {
  cursor: pointer;
}

.circleGreenAuto {
  background: $greenEnabled;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  margin: auto;
}

.circleRedAuto {
  background: red;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  margin: auto;
}

.circleYellowAuto {
  background: #f2c94c;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  margin: auto;
}

.circleBlackAuto {
  background: #58595b;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  margin: auto;
}

.mainRow {
  display: inline-flex;
  width: 100%;
  vertical-align: middle;
  justify-content: space-between;
  align-items: end;
  //padding: 0 .5rem;
}

.container {
  width: 100%;
  padding: 0 2rem;
  display: flex;
  flex-direction: column;
}

.title {
  margin-top: 3rem;
  margin-bottom: 1rem;
}

.tableArea {
  //margin:0 5rem;
  margin: 0 76px;
}

.searchUsersAlt {
  border: 1.5px solid #005DBF10;
  border-radius: 5px;
  padding-left: 0.5rem;
  background-color: #005DBF10;
  color: #005DBF;
  width: 270px;
  height: 28px;
}

.searchUsersAlt::placeholder {
  color: #005DBF50;
}

.inputText {
  border: 1.5px solid #7FADDE;
  flex: 1;
  border-radius: 5px;
  padding-left: 0.5rem;
  max-height: 24px;
  font-size: 12px;
}

.crudModalRow {
  display: flex;
}

.crudModalRow>div {
  margin-top: 0.5rem;
  margin-right: 0.5rem;
}

.crudModalRow>div:last-child {
  margin-right: 0;
}

.crudModalItemTiny {
  flex: 3;
  margin-right: 0.4rem;
  display: flex;
  flex-direction: column;
}

.crudModalItemSmall {
  flex: 7;
  display: flex;
  flex-direction: column;
}

.crudModalItemBig {
  flex: 10;
  margin: 0 0.2rem;
  display: flex;
  flex-direction: row;
}

.crudModalrightMargin {
  margin-right: 0.4rem;
}

.crudModalLabel {
  font-size: 0.75rem;
  margin: 0.4rem 0.3rem 0 0.3rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: $darkBlue;
}

.crudModalLabel {
  font-size: 0.75rem;
  margin: 0.4rem 0.3rem 0 0.3rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: $darkBlue;
}

.labelTex {
  font-size: 0.75rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: $darkBlue;
}

.labelText2 {
  font-size: 0.75rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: #6e6f7c;
}

.crudModalInputDate {
  font-size: 1vw;
  // flex: 1 1 auto;
}

e .crudModalInputDate::-webkit-calendar-picker-indicator {
  filter: invert(15%) sepia(92%) saturate(5062%) hue-rotate(201deg) brightness(93%) contrast(101%);
}

.crudModalZonaCuenta {
  display: flex;
  flex-direction: column;
  background-color: rgba(88, 89, 91, 0.05);
  border-radius: 0.5rem;
  margin-top: 1rem;
  padding: 0.5rem;
}

.crudModalZonaCuenta2 {
  background-color: rgba(0, 93, 191, 0.05);
  border-radius: 0.5rem;
  padding: 10px 20px;
}

.crudModalZonaPerfiles {
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
  padding: 0.5rem 2rem;
}

.crudModalPerfilesWrap {
  display: flex;
  flex-wrap: wrap;
  padding-left: 1rem;
}

.myCheckbox {
  margin: 0 1rem;
}

.myCheckboxLabel {
  margin-left: 0.5rem;
  text-transform: capitalize;
  vertical-align: middle;
}

.myCheckboxInput {
  width: 20px;
  height: 20px;
  border-radius: 4px;
  vertical-align: middle;
}

.flex3 {
  flex: 3;
}

.flex1 {
  flex: 1;
  display: flex;
  padding: 0 0.3rem;
}

.textCenter,
.genericTable>tbody>tr>.textCenter {
  text-align: center;
}

.textleft {
  text-align: left;
}

.blueSearch {
  background-color: rgba(0, 93, 191, 0.1);
  border-radius: 6px;
  width: 100%;
  border: none;
  padding: 0 0.5rem;
  height: 27px;
  color: $darkBlue;

  &::placeholder {
    color: rgba($darkBlue, 0.5);
  }
}

.btnPrimary {
  background-color: $clearBlue;
  border: 1.5px solid $clearBlue;
  font-family: "PT Sans";
  color: #fff;
  min-width: 110px;
  font-weight: bold;
  font-size: 15px;
  line-height: 19.41px;
  padding-top: 6px;
  padding-bottom: 6px;
  border-radius: 6px;
}

.btnPrimary:hover {
  background-color: $darkBlue;
  border: 1.5px solid $darkBlue;
  color: #fff;
}

.btnPrimaryAlt:disabled,
.btnPrimary:disabled {
  background-color: $clearGray;
  border: 1.5px solid $clearGray;
  color: #fff;
  opacity: 1;
  font-size: 15px;
}

.btnSecondary {
  background-color: #fff;
  border: 1.5px solid $clearBlue;
  font-family: "PT Sans";
  color: $clearBlue;
  min-width: 110px;
  font-weight: 700;
  font-size: 15px;
  line-height: 19.41px;
  padding: 6px 2rem;
  border-radius: 6px;
}

.btnSecondary:hover {
  background-color: $clearBlue;
  border: 1.5px solid $clearBlue;
  color: #fff;
}

.btnSecondary:disabled {
  background-color: #fff;
  border: 1.5px solid $clearGray;
  color: $clearGray;
  opacity: 1;
}

.btnSecondary:disabled img {
  filter: brightness(0) saturate(100%) invert(100%) sepia(1%) saturate(59%) hue-rotate(157deg) brightness(105%) contrast(62%);
}

.btnPrimaryAlt {
  background-color: $darkBlue;
  border: 1.5px solid $darkBlue;
  font-family: "PT Sans";
  color: #fff;
  min-width: 110px;
  font-weight: 700;
  font-size: 15px;
  line-height: 19.41px;
  padding-top: 6px;
  padding-bottom: 6px;
  border-radius: 6px;
}

.btnPrimaryAlt:hover {
  background-color: $darkerBlue;
  border: 1.5px solid $darkerBlue;
  color: #fff;
}

//button extra for modal
//111111111111111111111111111111111111
.buttonExtra1 {
  background-color: #fff !important;
  border: 1.5px solid $darkBlue !important;
  box-sizing: border-box;
  border-radius: 5px;

  font-family: "PT Sans";
  color: $darkBlue !important;
  min-width: 130px;
  font-weight: 700;
  font-size: 15px;
  line-height: 19.41px;
  padding-top: 6px;
  padding-bottom: 6px;
  display: flex;
  justify-content: center;
}

.buttonExtra1:hover {
  background-color: $darkBlue !important;
  border: 1.5px solid $darkBlue !important;
  color: #fff !important;
}

.buttonExtra1:hover>img {
  filter: brightness(0) invert(1);
}

.buttonExtra1:disabled {
  background-color: #fff !important;
  border: 1.5px solid $clearGray !important;
  color: $clearGray;
  opacity: 1;
}

.buttonExtra1:disabled {
  filter: brightness(0) saturate(100%) invert(83%) sepia(0%) saturate(3%) hue-rotate(198deg) brightness(101%) contrast(91%);
}

//2222222222222222222222222222222222222
.buttonExtra2 {
  background-color: #fff !important;
  border: 1.5px solid $clearBlue !important;
  box-sizing: border-box;
  border-radius: 5px;

  font-family: "PT Sans";
  color: $clearBlue !important;
  min-width: 130px;
  font-weight: 700;
  font-size: 15px;
  line-height: 19.41px;
  padding-top: 6px;
  padding-bottom: 6px;
  display: flex;
}

.buttonExtra2:hover {
  background-color: $clearBlue !important;
  border: 1.5px solid $clearBlue !important;
  color: #fff !important;
}

//3333333333333333333333333333333333333333333333
.buttonExtra3 {
  background-color: $darkBlue;
  border: 1.5px solid $darkBlue;
  box-sizing: border-box;
  border-radius: 5px;

  font-family: "PT Sans";
  color: #fff;
  min-width: 130px;
  font-weight: 700;
  font-size: 15px;
  line-height: 19.41px;
  padding-top: 6px;
  padding-bottom: 6px;
  display: flex;
}

.buttonExtra3:hover {
  background-color: $darkerBlue;
  border: 1.5px solid $darkerBlue;
  color: #fff;
}

.buttonExtra2:disabled,
.buttonExtra3:disabled {
  background-color: $clearGray;
  border: 1.5px solid $clearGray;
  color: #fff;
  opacity: 1;

  font-family: "PT Sans";
  color: #fff;
  min-width: 130px;
  font-weight: 700;
  font-size: 15px;
  line-height: 19.41px;
  padding-top: 6px;
  padding-bottom: 6px;
  display: flex;
}

.buttonExtra2:hover>img,
.buttonExtra2:disabled>img,
.buttonExtra3>img {
  filter: brightness(0) invert(1);
}

.buttonExtra1>img,
.buttonExtra2>img,
.buttonExtra3>img {
  height: 15px;
  vertical-align: middle;
  margin: auto 8px auto 0px;
}

//end extra buttons

.deleteicon {
  position: relative;
  flex: 1;
  display: flex;
}

.deleteicon span {
  position: absolute;
  display: block;
  top: -8px;
  right: -8px;
  width: 20px;
  height: 20px;
  font-size: 12px;
  text-align: center;
  color: #fff;
  background: $clearBlue;
  cursor: pointer;
  border-radius: 50%;
}

.deleteicon input {
  flex: 1;
}

.warningIcon {
  background-image: url("../../assets/img/icons/AlertTriangle.svg");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 1.2rem;
  height: 1.2rem;
  margin: auto;
}

.deniedCross {
  background-image: url("../../assets/img/icons/FalseCross.svg");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 1rem;
  height: 1rem;
  margin: auto;
}

.checkMark {
  background-image: url("../../assets/img/icons/checkOnTable.svg");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 1.2rem;
  height: 1.2rem;
  margin: auto;
}

.shade {
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
}

.greenCheck {
  background-image: url("../../assets/img/icons/CheckMark.svg");
  background-repeat: no-repeat;
  width: 1.5rem;
  height: 1.1rem;
  width: "-webkit-fill-available";
  margin: auto;
}

.alertTriangle {
  background-image: url("../../assets/img/icons/Alert.svg");
  background-repeat: no-repeat;
  width: "-webkit-fill-available";
  width: 1.5rem;
  height: 1.1rem;
  margin: auto;
}

.shade {
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
}

.titleDeference {
  font-family: PT Sans Caption;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  color: #005dbf;
}

.titleTax {
  font-family: PT Sans Caption;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #005dbf;
}

.valorDeference {
  font-family: PT Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  color: #00a551;
}

.labelIntoButton {
  font-family: PT Sans Caption;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #58595b;
}

.darkGrayText {
  color: $darkGray;
}

.ordDarkGrayText {
  color: $ordDarkGray !important;
}

.ordAquaMarineText {
  color: $ordAquaMarine;
}

.ordAquaMarineBgHover:hover {
  background-color: $ordAquaMarine !important;
}

.horizontalBottomLine {
  color: $darkGray;
  width: 92%;
  margin-left: 4%;
}

.articleSearch {
  transition: transform 0.3s;
  width: 25px;
  background: transparent;
  border: none;
  margin: 0;
  font-size: 12px;
  line-height: 16px;
  color: #58595b;
}

.valorDeference {
  font-family: PT Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  color: #00a551;
}

.labelIntoButton {
  font-family: PT Sans Caption;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #58595b;
}

.circleAddButton {
  width: 16px;
  height: 16px;
  background-size: contain;
  cursor: pointer;
  background-image: url("../../assets/img/icons/addbutton.svg");
}

.circleAddButton:hover {
  background-image: url("../../assets/img/icons/addbuttonhover.svg");
}

.warningIcon {
  background-image: url("../../assets/img/icons/AlertTriangle.svg");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 1.2rem;
  height: 1.2rem;
  margin: auto;
}

.deniedCross {
  background-image: url("../../assets/img/icons/FalseCross.svg");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 1rem;
  height: 1rem;
  margin: auto;
}

.btnNewHover {
  cursor: pointer;
  filter: grayscale(1);
  align-self: center;
  padding-left: 10px;
}

.btnNewHover:hover {
  filter: none;
}

.inputSearch {
  background-color: #005dbf1a;
  border: none;
  border-radius: 10px;
}

.checkMark {
  background-image: url("../../assets/img/icons/checkOnTable.svg");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 1.2rem;
  height: 1.2rem;
  margin: auto;
}

.text {
  font-family: $font-Secular;
  color: #003f80;
  font-weight: Bold;
  font-size: 15px;
  padding: 0 0 0 0;
  margin: 0 0 9px 0;
  font-family: PT Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 7px;
}

.cancellationsDatesAseguradora {
  font-family: PT Sans Caption !important;
  color: #6e6f7c;
  font-weight: Bold;
  font-size: 15px;
  margin: 0 0 0 0;
  font-family: PT Sans;
  font-style: normal;
}

.cancellationsDatesAseguradora2 {
  font-family: PT Sans Caption !important;
  color: #6e6f7c;
  font-size: 15px;
  margin: 0 0 0 0;
  font-family: PT Sans;
  font-style: normal;
}

.row1ModalAnulaciones {
  padding: 20px 40px !important;
  background: #f1fdff;
}

.row2ModalAnulaciones {
  padding: 20px 40px !important;
  background: white;
}

.subtitle {
  font-family: $font-Secular;
  color: $darkBlue;
  font-weight: Bold;
  font-size: 15px;
  line-height: 19px;
  padding: 0 0 0 0;
  margin: 0 0 9px 0;
  font-family: PT Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
}

.circleAddButton {
  width: 16px;
  height: 16px;
  background-size: contain;
  cursor: pointer;
  background-image: url("../../assets/img/icons/addbutton.svg");
}

.circleAddButton:hover {
  background-image: url("../../assets/img/icons/addbuttonhover.svg");
}

.darkBlueText {
  color: $darkBlue !important;
}

.darkerBlueText {
  color: $darkerBlue !important;
}

.clearBlueSearch {
  border: rgba(0, 93, 191, 0.1);
  background-color: rgba(0, 93, 191, 0.1);
  border-radius: 6px;
  width: 70%;
  height: 1.8rem;
  padding-left: 5px;
}

.verticalLine {
  border: none;
  border-left: 1px solid hsla(200, 10%, 50%, 100);
  height: 100vh;
  width: 1px;
}

.bgClearBlue {
  background-color: $bgFormBlue;
}

.titlePayrollProvision {
  margin-top: 3rem;
  margin-bottom: 1rem;
  color: #58595b;
  font-size: 28px;
  line-height: 30px;
}

.subTitlePayrollProvision {
  color: #005dbf;
  font-size: 30px;
  font-weight: bold;
}

.clearBlueText {
  color: #4d84fe;
}

.bgColorClearBlue {
  background-color: $bgFormBlue;
  // background-color: 'red';
}

.emptyCircle {
  width: 25px;
  height: 25px;
  border: 2px solid green;
  opacity: 0.3;
  border-radius: 100%;
}

.x {
  width: 10%;
  align-self: self-end;
  cursor: pointer;
  border-radius: 100%;
}

.noBorderInput {
  border: none !important;
  background-color: $white;
  border-radius: 15px;
}

.noBorderInput:focus {
  outline: none;
}

.blackGrayBoldFont {
  font-family: PT Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 19px;
  color: #58595b;
}

.slideEffectbtn {
  background-color: white;
  // margin-left: 15px;
  border-radius: 15px 15px 0 0;
  border: 1 px solid #8080805e;
  border-bottom: none;
}

.fw700End {
  font-size: 13px;
  align-items: flex-start;
}

.timeLineContainer {
  list-style-type: none;
}

.timeLineItem {
  position: relative;
  margin: 0;
  padding-bottom: 1em;
  padding-left: 20px;
}

.timeLineItem:before {
  content: "";
  background-color: $darkBlue;
  position: absolute;
  bottom: 0;
  top: 0;
  left: 6px;
  width: 3px;
}

.timeLineItem:after {
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' aria-hidden='true' viewBox='0 0 32 32' focusable='false'%3E%3Ccircle stroke='none' fill='%2962ff' cx='16' cy='16' r='10'%3E%3C/circle%3E%3C/svg%3E");
  position: absolute;
  left: 0;
  height: 15px;
  width: 15px;
}

.clearBlueBorder {
  border: 0.1px solid $clearBlue;

  border-radius: 5px;
}

.tableScroll {
  height: 24rem;
  overflow: auto;
  margin-top: 20px;
}

// .tableScrollBody {
//   height: 300px;
//   overflow-y: scroll;
//   display: block;
// }
.bgBlueHover:hover {
  background-color: $bgFormBlue;
  border-radius: 5px;
}

.bgBlueHover>b>span:hover {
  color: $darkBlue;
}

.noBorderFocus:focus {
  outline: 0px;
}

.accordinElipsis {
  width: 215px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.elipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.rwoReport {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) / 2);
  padding-left: calc(var(--bs-gutter-x) / 2);
  margin-top: var(--bs-gutter-y);
  // --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
}

.cardBoxContainer {
  border-radius: 10px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
  padding: 10px;
  width: 100%;
}

.cardBoxText {
  font-family: "PT Sans Caption";
  font-size: 13px;
  line-height: 17px;
  color: #58595b;
  margin: 0 0 8px 0;
}

.cardBoxTitle {
  font-family: "PT Sans";
  font-weight: bold;
  font-size: 15px;
  line-height: 19px;
  color: #005dbf;
  margin: 0 0 8px 0;
}

.cardBoxBottomText {
  font-family: "PT Sans";
  font-weight: bold;
  font-size: 15px;
  line-height: 19px;
  color: #58595b;
  margin: 0 16px 0 0;
}

.blueBtnHover {
  cursor: pointer;
  // filter: grayscale(1);
  color: $clearBlue;
  align-self: center;
  padding-left: 10px;
  border: 1px solid $clearBlue;
  background-color: $bgFormBlue;
  // background-color: black;
}

.blueBtnHover:hover {
  background-color: $tenueBlue;
}

.currentBtnStyle {
  cursor: pointer;
  // filter: grayscale(1);
  color: $clearBlue;
  align-self: center;
  padding-left: 10px;
  border: 1px solid $darkBlue;
  background-color: $white;
  // background-color: black;
}

.ellipsis {
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.blueOutline {
  color: $darkBlue;
  background-color: #fff;
  border-color: $darkBlue;
}

.blueOutline:hover {
  color: 2;
  background-color: $darkBlue;
  border-color: $darkBlue;
}

.scrollThis {
  height: 60vh;
  overflow: auto;
}

.grayBorderBottom {
  border-bottom: 1px solid #dce1e3;
  margin-bottom: 10%;
}

.labelFont {
  font-size: 0.8rem;
  color: #005dbf;
  font-family: "PT Sans Caption";
  line-height: 0.971rem;
  font-weight: 400;
}

.labelFontGray {
  font-size: 0.8rem;
  color: #58595b;
  font-family: "PT Sans Caption";
  line-height: 0.971rem;
  font-weight: 400;
}

.profileContainer {
  width: 135px;
  height: 135px;
  border-radius: 8%;
  border: 1px solid $darkBlue;
  object-fit: "cover";
  // filter: drop-shadow(0 0 8px #ff5722);
}

.f13 {
  font-size: 13px;
}

.f12 {
  font-size: 12px !important;
}

.iconWrap:hover>img {
  filter: brightness(0) saturate(100%) invert(17%) sepia(68%) saturate(4437%) hue-rotate(201deg) brightness(98%) contrast(100%);
}

.mLeft2 {
  margin-left: 2.8%;
}

.f95pt {
  font-size: 9.5pt;
}

.weirdBackGround {
  background: rgb(239, 244, 251);
  background: linear-gradient(90deg,
      rgba(239, 244, 251, 1) 82.5%,
      rgba(239, 244, 251, 1) 69%,
      rgba(255, 254, 254, 0) 69%,
      rgba(244, 238, 238, 0) 100%);
}

.weirdBackGround3 {
  background: rgb(239, 244, 251);
  // background: linear-gradient(90deg,
  //     rgba(239, 244, 251, 1) 82.5%,
  //     rgba(239, 244, 251, 1) 69%,
  //     rgba(255, 254, 254, 0) 69%,
  //     rgba(244, 238, 238, 0) 100%);
}

.weirdBackGround2 {
  background: rgb(239, 244, 251);
  background: linear-gradient(90deg,
      rgba(239, 244, 251, 1) 93%,
      rgba(239, 244, 251, 1) 69%,
      rgba(255, 254, 254, 0) 69%,
      rgba(244, 238, 238, 0) 100%);
}

.f16 {
  font-size: 16px;
}

.f15 {
  font-size: 15px;
}

.f14 {
  font-size: 14px;
}

.genericGrayContainer {
  background-color: $tableEven;
  border-radius: 10px;
  padding: 15px 20px;
}

.orangeText {
  color: $orange;
}

.blueCheck {
  background-image: url("../../assets/img/icons/blueCheck.svg");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 1.2rem;
  height: 1.2rem;
  margin: auto;
}

.clearBlueSearch {
  border: rgba(0, 93, 191, 0.1);
  background-color: rgba(0, 93, 191, 0.1);
  border-radius: 6px;
  width: 100%;
  height: 24px;
  padding-left: 5px;
  outline: none;
}

.bdRadiusTabsTop {
  border-radius: 1rem 1rem 0 0;
}

.bdRadiusZeroEight {
  border-radius: 0.8rem;
}

/* -------------------------------------------------------------------------- */
/*                              VERTICAL TIMELINE                             */
/* -------------------------------------------------------------------------- */
/* The actual timeline (the vertical ruler) */
.timeline {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
}

/* The actual timeline (the vertical ruler) */
.timeline::after {
  content: "";
  position: absolute;
  width: 3px;
  background-color: $darkBlue;
  top: 0;
  bottom: 0;
  left: 40%;
  margin-left: -3px;
  // height: 15vh;
  height: 300vh;
}

/* Container around content */
.timeLineContainer {
  padding: 0px 10px;
  position: relative;
  background-color: inherit;
  width: 40%;
}

/* The circles on the timeline */
.timeLineContainer::after {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  right: -6px;
  background-color: white;
  border: 2px solid $darkBlue;
  top: 15px;
  border-radius: 50%;
  z-index: 1;
}

/* Place the timeLineContainer to the left */
.left {
  left: 0;
  text-align: end;
}

/* Place the timeLineContainer to the right */
.right {
  left: 50%;
  margin-left: 18rem;
}

/* Add arrows to the left timeLineContainer (pointing right) */
// .left::before {
//   content: " ";
//   height: 0;
//   position: absolute;
//   top: 22px;
//   width: 0;
//   z-index: 1;
//   right: 30px;
//   border: medium solid white;
//   border-width: 10px 0 10px 10px;
//   border-color: transparent transparent transparent white;
// }

/* Add arrows to the right timeLineContainer (pointing left) */
// .right::before {
//   content: " ";
//   height: 0;
//   position: absolute;
//   top: 22px;
//   width: 0;
//   z-index: 1;
//   left: 30px;
//   border: medium solid white;
//   border-width: 10px 10px 10px 0;
//   border-color: transparent white transparent transparent;
// }

/* Fix the circle for timeLineContainers on the right side */
.right::after {
  left: -9px;
}

/* The actual content */
.content {
  padding: 0px 10px;
  background-color: white;
  position: relative;
  border-radius: 6px;
}

/* Media queries - Responsive timeline on screens less than 600px wide */
@media screen and (max-width: 600px) {

  /* Place the timelime to the left */
  .timeline::after {
    left: 31px;
  }

  /* Full-width timeLineContainers */
  .timeLineContainer {
    width: 100%;
    padding-left: 70px;
    padding-right: 25px;
  }

  /* Make sure that all arrows are pointing leftwards */
  .timeLineContainer::before {
    left: 60px;
    border: medium solid white;
    border-width: 10px 10px 10px 0;
    border-color: transparent white transparent transparent;
  }

  /* Make sure all circles are at the same spot */
  .left::after,
  .right::after {
    left: 15px;
  }

  /* Make all right timeLineContainers behave like the left ones */
  .right {
    left: 0%;
  }
}

/* ------------------------------------ - ----------------------------------- */
.rotate270Deegrees {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(270deg);
}

.simpleDropDown {
  border: none;
  background-color: white;
  color: #58595b;
}

.customTitle[title]:hover::after {
  background-color: red;
  content: attr(title);
  position: absolute;
  top: -100%;
  left: 0;
}

.noTextDecoration {
  text-decoration: none;
}

.backToTop {
  position: fixed;
  bottom: 20px;
  right: 20px;
  font-size: 20px;
  width: 40px;
  background: $bgFormBlue;
  color: $darkBlue;
  cursor: pointer;
  border-radius: 50%;

  border: none;
  box-shadow: 0 5px 10px #ccc;
}

// .backToTop:hover {
//   background: $clearBlue;
// }
.Search {
  border: 1px solid rgba(0, 93, 191, 0.1);
  background-color: rgba(0, 93, 191, 0.1);
  border-radius: 5px;
  width: 100%;
  padding: 0 0.5rem;
  height: 28px;
  color: #005dbf;
  margin-top: -2px;
}

.Search::placeholder {
  color: #005dbf70;
}

.clearGrayBorder {
  border: 1px solid gainsboro;
}

.subContainer {
  margin: 0 5rem;
}

.ordContainer {
  width: 100%;
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
}

.ordClearBlueBorderAll {
  border: 1px solid $clearBlueBorder;
}

.ordClearBlueBorder {
  border-bottom: 1px solid $clearBlueBorder;
}

.ordClearBlueRighBorder {
  border-right: 1px solid $clearBlueBorder;
}

.ordRightLineBlue {
  border-right: 5px solid $ordAquaMarine;
  height: auto;
}

/* ------------------------------ GRAY VARIANT ------------------------------ */
.ordGrayVariant {
  font-weight: bold;
  color: gray;
}

.ordGrayVariant:hover {
  color: $ordTableTextColor;
  cursor: pointer;
}

/* ---------------------------- DARK BLUE VARIANT --------------------------- */
.ordDarkBlueVariant {
  font-weight: bold;
  color: $ordBtnDarkBlueText;
}

.ordDarkBlueVariantNew {
  font-weight: bold;
  color: $ordBtnDarkBlueText;
}

.ordDarkBlueVariant:hover {
  color: $ordDarkBlueText;
  cursor: pointer;
}

/* --------------------------- CLEAR BLUE VARIANT --------------------------- */
.ordClearBlueVariant {
  font-weight: bold;
  color: $ordTableTextColor;
}

.styleCustomTitle {
  color: $ordDarkBlueText;
  font-weight: bold;
  cursor: pointer;
}

.ordClearBlueVariant:hover {
  color: $ordTableTextColor;
  cursor: pointer;
}

.ordDarkBlueText {
  color: $ordDarkBlueText !important;
}

.ordSubTitleStyle {
  color: $ordTableTextColor;
  font-weight: bold;
}

/* ----------------------------- BI TABLE STYLES ---------------------------- */
.ordBitableTitle {
  color: $ordDarkGray;
  font-size: 14px;
  font-weight: bold;
}

.ordBitableSubTitle {
  color: $ordDarkGray;
  font-size: 16px;
}

.ordDarkGrayText {
  color: $ordDarkGray;
}

.reducedContainer {
  width: 100%;
  padding: 2rem 5rem 5rem 8rem;
  display: flex;
  flex-direction: column;
}

/* ---------------------------------- ORDER --------------------------------- */
.ordSearchSections {
  // background-color: #d6f3f7;
  background-color: #e6f8fa;
  border: 1px solid #00b4cc;
  border-radius: 15px;
  text-align: center;
}

.ordSearchSectionBLueOpacity {
  background-color: #d6e0eb50;
  border: 1px solid #1a538d;
  border-radius: 15px;
  text-align: center;
}

.ordBgAquaMBlueOpacity {
  background-color: $ordBgAquaMBlueOpacity;
}

.ordBgClearBlue {
  background-color: #f6feff;
  // color: $ordDarkBlueText;
  // color: red;
}

.ordMultiTabHeader {
  background-color: #d6f3f7;
}

.outLineBigButton {
  border: 2px solid $ordAquaMarine;
  border-radius: 10px;
  color: $ordDarkBlueText;
  font-weight: bold;
  background-color: #fff;
  width: 100%;
  padding: 5px;
}

.outLineBigButton:hover {
  border: 2px solid $ordDarkBlueText;
  border-radius: 10px;
  color: $white;
  background-color: $ordAquaMarine;
  font-weight: bold;
  width: 100%;
  padding: 5px;
}

.f25 {
  font-size: 25px;
}

.f28 {
  font-size: 28px;
}

.ordClearBlueText {
  color: $ordTableTextColor !important;
}

.ordModalTitle {
  font-family: $font-Secular;
  color: $ordTableTextColor;
  font-weight: Bold;
  font-size: 26px;
  line-height: 34px;
  padding: 0 0 0 0;
  margin: 0 0 9px 0;
}

.ordAquaMarineBg40 {
  background-color: $ordBgAquaMBlueOpacity40;
}

.ordAquaMarineBg20 {
  background-color: $ordTableHeadBgColor;
  border-radius: 7px;
}

.ordAquaMarineBg60 {
  background-color: $ordBgAquaMBlueOpacity60;
}

.ordDashedBorder {
  height: 25vh;
  border: 2px dashed #ade0e7cc;
  border-radius: 15px;

  display: flex;
  justify-content: center;
  padding-top: 4rem;
}

.ordCenterXAndY {
  position: absolute;
  top: 50%;
  left: 50%;
}

.ordOrangeCard {
  // background: #fcf1e1;
  background: #fff7eb;
  border: 1px solid #f3c682;
  border-radius: 10px;
}

.ordOrangeCard:hover {
  background: #fcf1e1;
  cursor: pointer;
}

.ordClearBlueCard {
  // background: #fcf1e1;
  background: #e9f8fa;
  // border: 1px solid #f3c682;
  border-radius: 10px;
}

.ordBtnPrimary {
  background-color: $ordAquaMarine !important;
  font-family: "PT Sans" !important;
  color: #fff !important;
  min-width: 110px !important;
  font-weight: bold !important;
  font-size: 15px !important;
  line-height: 19.41px !important;
  padding-top: 6px !important;
  padding-bottom: 6px !important;
  border-radius: 6px !important;
}

.ordBtnPrimary:hover {
  background-color: $darkBlue !important;
  color: #fff !important;
}

.btnYesStyle {
  background-color: $darkBlue !important;
  font-family: "PT Sans" !important;
  color: #fff !important;
  min-width: 110px !important;
  font-weight: bold !important;
  font-size: 15px !important;
  line-height: 19.41px !important;
  padding-top: 6px !important;
  padding-bottom: 6px !important;
  border-radius: 6px !important;
}

.btnYesStyle:hover {
  background-color: $darkBlue;
  opacity: 0.9 !important;
  color: #fff !important;
}

.ordBtnPrimary:disabled {
  background-color: $clearGray !important;
  border: 1.5px solid $clearGray !important;
  color: #fff !important;
  opacity: 1 !important;
  font-size: 15px !important;
}

.ordBtnPrimaryDisabled:disabled {
  background-color: $clearGray !important;
  border: 1.5px solid $clearGray !important;
  color: #fff !important;
  opacity: 1 !important;
  font-size: 15px !important;
}

.ordBtnSecondary {
  border: 1px solid $ordAquaMarine !important;
  color: $ordAquaMarine !important;
  font-family: "PT Sans" !important;
  min-width: 110px !important;
  font-weight: bold !important;
  font-size: 15px !important;
  line-height: 19.41px !important;
  padding-top: 6px !important;
  padding-bottom: 6px !important;
  border-radius: 6px !important;
}

.ordBtnSecondary:disabled {
  background-color: gray;
  border: 1px solid gray;
  color: white;
}

.ordBtnSecondary:hover {
  background-color: $ordAquaMarine !important;
  color: white !important;
}

.btnCancelStyle {
  border: 1px solid $darkBlue !important;
  color: $darkBlue !important;
  font-family: "PT Sans Caption" !important;
  min-width: 110px !important;
  width: 110px !important;
  font-weight: bold !important;
  font-size: 15px !important;
  line-height: 19.41px !important;
  padding-top: 6px !important;
  padding-bottom: 6px !important;
  border-radius: 6px !important;
  text-align: center;
  cursor: pointer;
}

.btnCancelStyle:disabled {
  background-color: gray;
  border: 1px solid gray;
  color: white;
}

.btnCancelStyle:hover {
  background-color: $darkBlue !important;
  color: white !important;
}

.btnCancelStyle:hover img {
  filter: brightness(0) saturate(100%) invert(98%) sepia(0%) saturate(10%) hue-rotate(232deg) brightness(104%) contrast(102%);
}


.btnSaveBorrador {
  background-color: #01A0F6 !important;
  color: #fff !important;
  font-family: "PT Sans Caption" !important;
  min-width: 110px !important;
  font-weight: bold !important;
  font-size: 15px !important;
  line-height: 19.41px !important;
  padding-top: 6px !important;
  padding-bottom: 6px !important;
  border-radius: 6px !important;
}

.btnSaveBorrador:disabled {
  background-color: gray;
  border: 1px solid gray;
  color: white;
}

.btnSaveBorrador:hover {
  background-color: #005DBF !important;
  color: white !important;
}


.ordBordersemiCard {
  border: 2px solid #e9ecef;
  border-radius: 10px;
}

.f18 {
  font-size: 18px;
}

.f17 {
  font-size: 17px;
}

.f20 {
  font-size: 20px;
}

.ordOrangeText {
  color: #f39682 !important;
}

.ordBorderTop {
  border-top: 1px solid $clearBlueBorder;
}

.bdRadius10 {
  border-radius: 10px;
}

.ordTableShadow {
  box-shadow: 0px 0px 6px rgba(19, 19, 19, 0.2);
  border-radius: 10px;
  background: #ffffff;
}

.ordGrayInputSearch {
  background-color: #f5f7fa !important;
  padding: 0.1rem;
  border-radius: 5px;
}

.ordGrayInputSearch2 {
  background-color: $tableEven;
  padding: 0.1rem;
  border-radius: 5px;
}

.ordGrayInputSearch>input {
  border: none;
  background-color: #f5f7fa;
}

.ordGrayInputSearch>img {
  padding-right: 1rem;
}

.ordTableShadow {
  box-shadow: 0px 0px 6px rgba(19, 19, 19, 0.2);
  border-radius: 10px;
  background: #ffffff;
}

.ordAuthBtn {
  font-weight: bold;
  // color: #f3c682;
  background-color: white;
  box-shadow: 0px 0px 6px rgba(19, 19, 19, 0.2);
  border-radius: 5px;
}

.ordAuthBtn:hover {
  background-color: #a3e4ec;
  color: white !important;
}

.ordAuthBtn:focus {
  background-color: #1abcd2;
  color: white !important;
}

.ordGrayBorderBottom {
  border-bottom: 1px solid #dce1e3;
  margin-bottom: 1%;
}

.ordBlueInputSearch {
  background-color: #f5fcfd;
  padding: 0.1rem;
  border-radius: 5px;
}

.ordBlueInputSearch>input {
  border: none;
  background-color: #f5fcfd;
}

.ordBlueInputSearch>img {
  padding-right: 1rem;
}

/* ---------------------------------- buttons --------------------------------- */

.ordBtnAquaMarine {
  background-color: $ordAquaMarine;
  border: 1.5px solid $ordAquaMarine;
  font-family: "PT Sans";
  color: #fff;
  font-weight: bold;
  font-size: 24px;
  line-height: 31px;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 10px;
}

.ordBtnAquaMarine:disabled {
  background-color: $clearGray;
  border: 1.5px solid $clearGray;
  color: #fff;
  opacity: 1;
  font-size: 24px;
}

.ordBtnAquaMarineLarge:hover {
  background-color: $ordBgAquaMBlueOpacity60;
  border: 1.5px solid $ordBgAquaMBlueOpacity60;
  color: #fff;
}

.ordBtnAquaMarineLarge:disabled {
  background-color: $clearGray;
  border: 1.5px solid $clearGray;
  color: #fff;
  opacity: 1;
  font-size: 15px;
}

.ordBtnDarkBlue {
  background-color: $ordBtnDarkBlueText;
  border: 1.5px solid $ordBtnDarkBlueText;
  font-family: "PT Sans";
  color: #fff;
  font-weight: bold;
  font-size: 24px;
  line-height: 31px;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 10px;
}

.ordBtnDarkBlue:hover {
  background-color: $ordBtnDarkBlueOpacity60;
  border: 1.5px solid $ordBtnDarkBlueOpacity60;
  color: #fff;
}

.ordBtnDarkBlue:disabled {
  background-color: $clearGray;
  border: 1.5px solid $clearGray;
  color: #fff;
  opacity: 1;
  font-size: 15px;
}

.ordBtnDarkBlueSlim {
  background-color: $ordBtnDarkBlueText;
  border: 1.5px solid $ordBtnDarkBlueText;
  font-family: "PT Sans";
  color: #fff;
  font-weight: bold;
  font-size: 16px;
  line-height: 31px;
  padding: 5px 16px 5px 16px;
  border-radius: 5px;
}

.ordBtnDarkBlueSlim:hover {
  background-color: $ordBtnDarkBlueOpacity60;
  border: 1.5px solid $ordBtnDarkBlueOpacity60;
  color: #fff;
}

.ordBtnDarkBlueSlim:disabled {
  background-color: $clearGray;
  border: 1.5px solid $clearGray;
  color: #fff;
  opacity: 1;
  font-size: 15px;
}

.ordCardBoxContainer {
  min-height: 100%;
  max-width: 100%;
  box-shadow: 0px 0px 6px rgba(19, 19, 19, 0.2);
  border-radius: 10px;
  background-color: #fff;
}

// style telemedicina
.tlnContainer {
  width: 100%;
  padding: 0 6rem 0 1rem;
  display: flex;
  flex-direction: column;
}

.tlnTextPrimary {
  color: #00b4cc;
}

.tlnTextDark {
  color: #1a538d;
}

.fwBold {
  font-weight: bold;
}

.tlnTextGray {
  color: #6e6f7c;
}


.tlnBtnDark {
  background-color: #003f80;
  padding: 10px 15px;
  border-radius: 5px;
}

.tlnBtnDark:disabled {
  background-color: #6e6f7c;
  padding: 10px 15px;
  border-radius: 5px;
}

.tlnMiniCard {
  box-shadow: 0px 0px 6px #13131320;
  border-radius: 10px;
}

.tlnMiniCard button {
  background-color: #00b4cc;
  color: #ffffff;
  border-radius: 5px;
  padding: 10px 16px;
  border: none;
  min-width: 110px;
}

.tlnBlueInputSearch {
  background-color: #f5f7fa;
  padding: 0.1rem;
  border-radius: 5px;
}

.tlnBlueInputSearch>input {
  border: none;
  background-color: #f5f7fa;
}

.tlnBlueInputSearch>img {
  padding-right: 1rem;
}

.tlnRoundInputSearch {
  background-color: #f5f7fa;
  border-radius: 5px;
  color: #6e6f7c;
  border: 1px solid #a3bad1;
  height: 32px;
  font-size: 12px !important;
}

.tlnRoundInputSearch:focus {
  color: gray;
  box-shadow: none;
}

.tlnRoundInputSearch::placeholder {
  color: #a2a3ab;
}

.inputPlaceholder::placeholder {
  color: #a2a3ab;
}

// .cardHover {
//   background-color: red;
// }
.cardHover:hover {
  background-color: #e9ecef;
}

.bgClearYellow {
  background-color: $ordClearYellow;
}

.clearBlueHover:hover {
  background-color: $ordBgAquaMBlueOpacity50;
}

.tlndrawer__title {
  color: #6e6f7c;
  font-weight: bold;
  font-size: 24px;
  font-family: "PT Sans Caption";
}

.tlndrawer__header {
  padding: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ddd;
}

.tlndrawer__close {
  /* Optional */
  margin: 0;
  padding: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;
  background-image: url("../../assets/img/icons/close.svg");
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  margin-left: 1rem;
  background-size: 20px;
}

.tlndrawer__content {
  overflow-x: hidden;
  overflow-y: auto;
  height: 80%;
  flex-grow: 1;
}

.tlnBtnPrimary {
  color: white;
  background-color: #003f80;
  font-family: "PT Sans";
  min-width: 110px;
  font-weight: bold;
  font-size: 15px;
  line-height: 19.41px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 6px;
}

.tlnBtnPrimary:disabled {
  background-color: #ccd9e6;
  border: 1px solid #ccd9e6;
  color: white;
}

.tlnBtnPrimary:hover {
  background-color: #5c84ad;
  color: white;
}

.tlnBtnPrimaryligth {
  color: white;
  background-color: #ffffff;
  border: 1px solid #003f80;
  color: #003f80;
  font-family: "PT Sans";
  min-width: 110px;
  font-weight: bold;
  font-size: 15px;
  line-height: 19.41px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 6px;
}

.tlnBtnPrimaryligth:disabled {
  border: 1px solid #cbcbd0;
  color: #cbcbd0;
}

.tlnBtnPrimaryligth:hover {
  background-color: #5c84ad;
  border: 1px solid #5c84ad;
  color: #ffffff;
}

.tlnBtnSecundaryligth {
  color: white;
  background-color: #ffffff;
  border: 1px solid #00b4cc;
  color: #00b4cc;
  font-family: "PT Sans";
  min-width: 110px;
  font-weight: bold;
  font-size: 15px;
  line-height: 19.41px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 6px;
}

.tlnBtnSecundaryligth:disabled {
  border: 1px solid #cbcbd0;
  color: #cbcbd0;
}

.tlnBtnSecundaryligth:hover {
  background-color: #00b4cc;
  border: 1px solid #00b4cc;
  color: #ffffff;
}

.ordBorderedBox {
  border: 1px solid #9cb5ce;
  border-radius: 10px;
}

.tlnBtnNew {
  background-image: url("../../assets/img/icons/add-check.svg");
  width: 15px;
  height: 15px;
  cursor: pointer;
  background-size: cover;
  background-repeat: no-repeat;
}

.ordGrayVariant:hover .tlnBtnNew {
  background-image: url("../../assets/img/icons/add-check-blue.svg");
}

.tlnMiniCont {
  background: rgba(214, 243, 247, 0.5);
  border-radius: 10px;
}

.tlnMiniCont span {
  color: #a2a3ab;
  font-size: 14px;
}

.tlnMiniCont b {
  color: #6e6f7c;
  font-weight: bold;
  font-size: 24px;
}

.tlnCard {
  border: 1px solid #ccd9e6;
  box-sizing: border-box;
  box-shadow: 0px 0px 6px 0px #13131333;
  border-radius: 10px;
  padding: 16px 24px;
  color: #6e6f7c;
}

.ordClearYellow {
  background-color: $ordClearYellow !important;
  // background: red !important;
}

.ordClearYellowOpacity20 {
  background-color: $ordClearYellowOpacity20 !important;
}

.ordBgAquamarine {
  background-color: $ordAquaMarine !important;
}

.editBlueHover:hover {
  content: url("../../assets/img/icons/PenFullBlue.svg");
}

.copyBlueHover:hover {
  content: url("../../assets/img/icons/ordBlueCopy.svg");
}

.deleteBlueHover:hover {
  content: url("../../assets/img/icons/blueTrash.svg");
}

.ordAddHoverBtn {
  content: url("../../assets/img/icons/add-check.svg");
}

.ordAddHoverBtn:hover {
  content: url("../../assets/img/icons/blueAdd.svg");
}

.ordClearGrayText {
  color: $clearGray;
}

.hoverClearGrayToDark:hover {
  color: $darkGray;
}

.ordOrangeBgButton {
  background-color: #f0c88d2e;
  border-radius: 10px;
  // font-size: 12px;
  color: #f3c682;
  padding: 6px;
}

.ordGreenBgButton {
  background-color: #fafdf6;
  border-radius: 10px;
  // font-size: 12px;
  color: #83c036;
  padding: 6px;
}

.ordRedBgButton {
  background-color: #f0c88d2e;
  border-radius: 10px;
  // font-size: 12px;
  color: #f39682;
  padding: 6px;
}

.rotate180Degrees {
  transform: rotate(180deg);
}

.rotate0Degrees {
  transform: rotate(0deg);
}

.pRight1Rem {
  padding-right: 1rem !important;
}

.pLeft1Rem {
  padding-left: 1rem !important;
}

.ordGreenBG {
  background-color: #dff0c8 !important;
}

.ordClearGreenBG {
  background-color: #eff8e4 !important;
}

.rightLineGray {
  // padding-right: 2px;
  // padding-left: 2px;
  border-right: 1px solid #cecece;
}

/* dialog style and drawer styles table list patient module Appointment */

.app_mr_100 {
  margin-right: 100px !important;
}

.app_container_close {
  width: 100%;
  margin: auto;
  padding: 1rem;
}

.app_container_drawer {
  width: 100%;
  margin: auto;
  padding: 1rem;
  max-width: 320px;
  display: flex;
  height: calc(100% - 62px);
  flex-direction: column;
}

.app_container_drawer2 {
  width: 100%;
  margin: auto;
  padding: 0rem 2rem;
  display: flex;
  height: calc(100% - 62px);
  flex-direction: column;
}

.app_drawer {
  width: 100%;
  height: 100%;
  display: flex;
  max-width: 320px;
  flex-direction: column;
}

.app_drawer2 {
  width: 100%;
  height: 100%;
  display: flex;
  width: 650px;
  flex-direction: column;
}

.app_drawer3 {
  width: 100%;
  height: 92vh;
  display: flex;
  flex-direction: column;
}

.app_title_drawer {
  font-style: normal;
  font-weight: 700;
  font-size: 35px;
  color: #1a538d;
  padding-left: 5px;
  font-family: "PT Sans Caption";
}

.app_name_drawer {
  font-size: 14px;
  color: #003f80;
  font-weight: 400;
  font-style: normal;
  font-family: "PT Sans Caption";
}

.app_description_drawer {
  color: #6e6f7c;
  font-size: 13px;
  font-weight: 400;
  font-style: normal;
  margin-bottom: 5px;
  overflow-wrap: break-word;
  font-family: "PT Sans Caption";
}

.app_container_date_drawer {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  justify-content: flex-end;
}

.app_container_state {
  background: #fafdf6;
  border-radius: 10px;
}

.app_container_new_patient {
  gap: 0.5rem;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: flex-end;
}

.app_new_item {
  font-weight: 700;
  font-size: 16px;
  color: #6e6f7c;
  font-style: normal;
  font-family: "PT Sans Caption";
}

.app_state_true {
  font-size: 12px;
  font-weight: 400;
  color: #83c036;
  font-style: normal;
  font-family: "PT Sans Caption";
}

.app_state_false {
  font-size: 12px;
  font-weight: 400;
  color: #f39682;
  font-style: normal;
  font-family: "PT Sans Caption";
}

.app_date_drawer {
  font-size: 12px;
  color: #003f80;
  font-weight: 400;
  font-style: normal;
  font-family: "PT Sans Caption";
}

.app_container_form_drawer {
  width: 100%;
  margin: auto;
  padding: 0.8rem;
  max-width: 280px;
  border-radius: 15px;
  border: 1px solid #00b4cc;
  background-color: #e7fcff8c;
}

.app_border_drawer {
  border: 1px solid #c5c5cb;
}

.app_bg_table_appointment {
  background-color: #fcf1e1 !important;
}

.app_container_content_drawer {
  width: 100%;
  min-height: 300px;
  display: flex;
  overflow: auto;
  margin-top: 10px;
  margin-bottom: 15px;
  flex-direction: column;
  flex: 1 1 auto;
}

.app_container_not_notes {
  width: 100%;
  height: 500px;
  margin: auto;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.app_container_tras_notes {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.app_container_info {
  gap: 10px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.app_simpleTabs {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom: 1px solid #a3e4ec;
  transition: step-start;
}

.app_title_info {
  font-weight: 700;
  color: #6e6f7c;
  font-style: normal;
  font-size: 14px;
  font-family: "PT Sans Caption";
}

.app_subtitle_info {
  font-style: normal;
  font-weight: 400;
  color: #6e6f7c;
  font-size: 12px;
  font-family: "PT Sans Caption";
}

.app_subtitle_info_policy {
  font-style: normal;
  font-weight: 400;
  color: #6e6f7c;
  font-size: 12px;
  margin-bottom: 20px;
  font-family: "PT Sans Caption";
}

.app_container_biTable {
  margin-top: 10px;
  margin-left: 30px;
}

.app_container_biTable>span {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #003f80;
  margin-bottom: 10px;
  font-family: "PT Sans Caption";
}

.app_container_biTable_title {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #003f80;
  font-family: "PT Sans Caption";
}

.app_title_order {
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  color: #00b4cc;
  font-family: "PT Sans Caption";
}

.app_simpleTabsActive {
  background-color: #fff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-top: 1px solid #a3e4ec;
  border-right: 1px solid #a3e4ec;
  border-left: 1px solid #a3e4ec;
  transition: step-start;
}

.app_list_group {
  border: none !important;
  display: block !important;
  font-weight: 700 !important;
  font-size: 15px !important;
  color: #a2a3ab !important;
  position: relative !important;
  font-style: normal !important;
  border-radius: 0px !important;
  padding: 0.5rem 1rem !important;
  text-decoration: none !important;
  background-color: transparent !important;
  font-family: "PT Sans Caption" !important;
}

.app_list_group_active {
  z-index: 2 !important;
  color: #003f80 !important;
  font-weight: bold !important;
  border-color: transparent !important;
  background-color: transparent !important;
  border-left: 3px solid #003f80 !important;
}

.app_list_item {
  border: none !important;
  font-weight: 700 !important;
  font-size: 15px !important;
  color: #a2a3ab !important;
  font-family: "PT Sans Caption" !important;
}

.app_container_cards {
  gap: 4rem;
  display: flex;
}

.app_container_card_ord {
  gap: 4rem;
  display: flex;
}

.app_card_medicine {
  gap: 2rem;
  display: flex;
}

.app_container_card_medicine {
  cursor: pointer;
  padding: 1rem;
  border-radius: 10px;
  margin-bottom: 10px;
  background: #ffffff;
  flex-direction: row !important;
  box-shadow: 4px 4px 6px -4px rgba(24, 39, 75, 0.12),
    0px 8px 8px -4px rgba(0, 63, 128, 0.08);
}

.app_container_card_medicine_2 {
  display: grid;
  grid-template-columns: 100px 3fr;
  cursor: pointer;
  padding: 1rem;
  border-radius: 10px;
  margin-bottom: 10px;
  background: #ffffff;
  box-shadow: 4px 4px 6px -4px rgba(24, 39, 75, 0.12),
    0px 8px 8px -4px rgba(0, 63, 128, 0.08);
}

.app_container_info_card {
  display: flex;
  flex-direction: column;
}

.rowGap5 {
  row-gap: 5px;
}

.app_title_order {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  color: #1a538d;
  font-family: "PT Sans Caption";
}

.app_title_card_order {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #6e6f7c;
  font-family: "PT Sans Caption";
}

.app_title_card_programming {
  font-style: normal;
  font-size: 14px;
  color: #6e6f7c;
  width: 100%;
  max-width: 150px;
  font-family: "PT Sans Caption";
}

.app_subtitle_card_order {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #6e6f7c;
  font-family: "PT Sans Caption";
}

.app_container_icon {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
}

.app_background_image {
  border: 0px;
  width: 100%;
  height: 100%;
  min-height: 35px;
  min-width: 320px;
  padding-left: 5px;
  color: #6e6f7c;
  border-radius: 5px;
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-color: #f5f7fa;
  background-image: url("../../assets/img/icons/ordBlueSearch.svg");
}

.app_background_out_image {
  border: 0px;
  width: 100%;
  height: 100%;
  min-height: 35px;
  min-width: 320px;
  padding-left: 5px;
  color: #6e6f7c;
  border-radius: 5px;
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-color: #f5f7fa;
  background-image: none;
}

.app_container_options {
  display: flex;
  margin-top: 10px;
  margin-bottom: 16px;
  align-items: center;
}

.app_container_detail_appointment {
  display: flex;
  margin-top: 10px;
  flex-direction: column;
}

.app_container_w {
  width: 100%;
  display: flex;
  max-width: 170px;
  flex-direction: column;
}

.app_container_medicine {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.app_container_medicine_time {
  width: 100%;
  display: flex;
  min-width: 70px;
  flex-direction: column;
}

.app_title_info_card {
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  color: #6e6f7c;
  font-family: "PT Sans Caption";
}

.app_subtitle_info_card {
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  color: #6e6f7c;
  font-family: "PT Sans Caption";
}

.app_title_info_card_medicine {
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  color: #6e6f7c;
  font-family: "PT Sans Caption";
}

.app_subtitle_info_card_medicine {
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  color: #6e6f7c;
  font-family: "PT Sans Caption";
  inline-size: 77px;
  overflow-wrap: break-word;
}

.app_title_detail_patient {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  color: #1a538d;
  font-family: "PT Sans Caption";
}

.app_container_multitab {
  width: 100%;
  padding: 1rem;
}

.app_container_photo {
  width: 100%;
  height: 70px;
  max-width: 80px;
  border-radius: 50%;
  object-fit: contain;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.app_title_not_notes {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #6e6f7c;
  font-family: "PT Sans Caption";
}

.app_title_input {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #00b4cc;
  line-height: 16px;
  font-family: "PT Sans Caption";
}

.app_multitab_background {
  background: #f5fcfd;
  border-radius: 10px 10px 0px 0px;
}

.app_multitab_sin_background {
  border-bottom: 1px solid #a3e4ec;
}

.app_container_alert {
  gap: 12px;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.app_container_alert>span {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #003f80;
  font-family: "PT Sans Caption";
}

.container_info_required {
  margin-bottom: 22px;
}

.app_multitab_title {
  font-weight: 400;
  font-size: 15px;
  font-style: normal;
  color: #00b4cc !important;
  font-family: "PT Sans Caption";
}

.app_roundInput {
  height: 35px;
  border: none;
  color: #a3bad1;
  border-radius: 6px;
  background-color: #f5f7fa;
}

.app_roundInput:focus {
  color: #a3bad1;
  background-color: #f5f7fa;
}

.app_roundSearch {
  height: 32px;
  border: none;
  color: #a3bad1;
  border-radius: 6px;
  background-color: #f5f7fa;
}

.app_roundSearch::placeholder {
  color: #a3bad1;
}

.app_roundInput::placeholder {
  color: #a3bad1;
}

.app_roundSearch:focus {
  color: #a3bad1 !important;
  background-color: #f5f7fa;
}

.app_card_custom {
  padding: 0.5rem;
  cursor: pointer;
  border-radius: 10px;
  margin-bottom: 10px;
  padding: 0.5rem;
  box-shadow: 1px 0px 1px rgba(43, 71, 139, 0.15);
  background: #ffffff;
  box-shadow: 4px 4px 6px -4px rgba(24, 39, 75, 0.12),
    0px 8px 8px -4px rgba(0, 63, 128, 0.08);
}

.app_card_custom_ordering {
  padding: 1rem;
  cursor: pointer;
  border-radius: 10px;
  margin-bottom: 10px;
  background: #ffffff;
  box-shadow: 4px 4px 6px -4px rgba(24, 39, 75, 0.12),
    0px 8px 8px -4px rgba(0, 63, 128, 0.08);

  &:hover {
    background-color: #e8e8ea;
  }
}

.app_card_custom_programming {
  padding: 1rem;
  cursor: pointer;
  max-width: 832px;
  margin: auto;
  border-radius: 10px;
  margin-bottom: 10px;
  background: #ffffff;
  box-shadow: 4px 4px 6px -4px rgba(24, 39, 75, 0.12),
    0px 8px 8px -4px rgba(0, 63, 128, 0.08);

  &:hover {
    background-color: #e8e8ea;
  }
}

.app_card_custom_shedule {
  padding: 1rem;
  cursor: pointer;
  max-width: 832px;
  margin: auto;
  border-radius: 10px;
  margin-bottom: 10px;
  background: #e6f8fa;
  border: 1px solid #a3e4ec;
}

.app_container_card_scroll {
  width: 100%;
  height: 55vh;
  overflow: auto;
  margin-bottom: 10px;
}

.app_container_scroll {
  width: 100%;
  height: 375px;
  overflow: auto;
  max-width: 816px;
}

.app_title_orderPed {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  color: #6e6f7c;
  font-family: "PT Sans Caption";
}

.app_container_scroll::-webkit-scrollbar {
  width: 14px;
  background-color: transparent;
}

/* background of the scrollbar except button or resizer */
.app_container_scroll::-webkit-scrollbar-track {
  background-color: transparent;
}

.app_container_scroll::-webkit-scrollbar-track:hover {
  background-color: transparent;
}

/* scrollbar itself */
.app_container_scroll::-webkit-scrollbar-thumb {
  background-color: #ccd9e6;
  border-radius: 16px;
  border: 5px solid #f6feff;
}

.app_container_scroll::-webkit-scrollbar-thumb:hover {
  background-color: #a0a0a5;
  border: 4px solid #f4f4f4;
}

/*  */

.app_container_card_scroll::-webkit-scrollbar {
  width: 14px;
  background-color: transparent;
}

/* background of the scrollbar except button or resizer */
.app_container_card_scroll::-webkit-scrollbar-track {
  background-color: transparent;
}

.app_container_card_scroll::-webkit-scrollbar-track:hover {
  background-color: transparent;
}

/* scrollbar itself */
.app_container_card_scroll::-webkit-scrollbar-thumb {
  background-color: #ccd9e6;
  border-radius: 16px;
  border: 5px solid #fff;
}

.app_container_card_scroll::-webkit-scrollbar-thumb:hover {
  background-color: #a0a0a5;
  border: 4px solid #f4f4f4;
}

.app_container_content_drawer::-webkit-scrollbar {
  width: 14px;
  background-color: #fff;
}

/* background of the scrollbar except button or resizer */
.app_container_content_drawer::-webkit-scrollbar-track {
  background-color: #fff;
}

.app_container_content_drawer::-webkit-scrollbar-track:hover {
  background-color: #f4f4f4;
}

/* scrollbar itself */
.app_container_content_drawer::-webkit-scrollbar-thumb {
  background-color: #ccd9e6;
  border-radius: 16px;
  border: 5px solid #fff;
}

.app_container_content_drawer::-webkit-scrollbar-thumb:hover {
  background-color: #a0a0a5;
  border: 4px solid #f4f4f4;
}

.app_container_dialog_gray {
  width: 100%;
  max-width: 30px;
  cursor: pointer;
  text-align: center;
  border-radius: 5px;
  background-color: #e8e8ea;
}

.app_container_dialog_blue {
  width: 100%;
  max-width: 30px;
  cursor: pointer;
  text-align: center;
  border-radius: 5px;
  background-color: #00b4cc;
}

.app_container_gray {
  width: 100%;
  min-width: 30px;
  cursor: pointer;
  text-align: center;
  border-radius: 5px;
  background-color: #e8e8ea;
}

.app_container_blue {
  width: 100%;
  min-width: 30px;
  cursor: pointer;
  text-align: center;
  border-radius: 5px;
  background-color: #00b4cc;
}

.app_text_dialog {
  color: #fff;
  text-transform: uppercase;
}

.app_title_new_item {
  font-style: normal;
  font-size: 14px;
  font-weight: 700;
  color: #1a538d;
  font-family: "PT Sans Caption";
}

.ordDisabledRow {
  background-color: #f9f9fa !important;
  color: #cbcbd0 !important;
}

.ordBgYellow {
  background-color: #faf4c3 !important;
}

.selectedPdfPage {
  text-align: center;
  // color: $ordCleaBlue;
  background-color: #d6f3f7;
  border-radius: 62px;
}

.inputDate {
  color: #003f80;
}

.inputDate:focus {
  color: #003f80;
  outline: none;
  font-weight: bold;
}

.btnGenerateRep {
  background-color: $ordAquaMarine;
  border: 1.5px solid $ordAquaMarine;
  font-family: "PT Sans";
  color: #fff;
  min-width: 110px;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  padding-top: 6px;
  padding-bottom: 6px;
  border-radius: 6px;
  width: 63px;
  height: 40px;
}

.btnGenerateRep:hover {
  background-color: $darkerBlue;
  border: 1.5px solid $darkerBlue;
  color: #fff;
}

// .btnPrimaryAlt:disabled,
.btnGenerateRep:disabled {
  background-color: $clearGray;
  border: 1.5px solid $clearGray;
  color: #fff;
  opacity: 1;
  font-size: 15px;
}

.ordDarkBlueTextFontBig {
  color: $ordDarkBlueText !important;
  font-size: 37px;
}

.referenceReportsPage {
  width: 100%;
  padding-right: 3rem;
}

.appItemCard {
  height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
}

.appColorArrow {
  filter: brightness(0) saturate(100%) invert(43%) sepia(6%) saturate(736%) hue-rotate(198deg) brightness(98%) contrast(85%);
}

.app_container_dialog_gray {
  width: 100%;
  max-width: 30px;
  cursor: pointer;
  text-align: center;
  border-radius: 5px;
  background-color: #e8e8ea;
}

.app_container_dialog_blue {
  width: 100%;
  max-width: 30px;
  cursor: pointer;
  text-align: center;
  border-radius: 5px;
  background-color: #00b4cc;
}

.appBlueButton {
  border: 1px solid #00b4cc;
  border-radius: 10px;
  color: white;
  font-weight: bold;
  background-color: #00b4cc;
  width: 100%;
  padding: 5px;
}

.inputDateServiceReports {
  font-family: $font-Secular;
  color: #003f80 !important;
  text-transform: uppercase;
}

.inputDateServiceReports:focus {
  color: #003f80 !important;
  font-weight: bold;
}

.fontFamiTitle {
  font-family: PT Sans;
}

.shadeRadius {
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
}

.ordsemiCardContainer {
  width: "100%";
  min-width: "20rem";
  height: "100%";
}

.ordsemiCardContainer {
  width: "100%";
  min-width: "20rem";
  height: "100%";
}

.ordAlertCustom {
  background-color: #f39682;
  color: white;
  border-radius: 5px;
  font-size: 14px;
}

.ordHoverBlueText {
  color: #00b4cc !important;
  text-decoration: underline;
}

.ordHoverBlueText:hover {
  color: #005dbf !important;
}

.ordDarkBlueBtn {
  color: white;
  background-color: $ordBtnDarkBlueText;
  font-family: "PT Sans";
  min-width: 110px;
  font-weight: bold;
  font-size: 15px;
  line-height: 19.41px;
  padding-top: 6px;
  padding-bottom: 6px;
  border-radius: 6px;
}

.ordDarkBlueBtn:disabled {
  background-color: gray;
  border: 1px solid gray;
  color: white;
}

.ordDarkBlueBtn:hover {
  background-color: $ordDarkBlueOpacity;
  color: white;
}

.pl10Pr10 {
  padding-left: 10%;
  padding-right: 10%;
}

.ordsemiCardContainer {
  width: "100%";
  min-width: "20rem";
  height: "100%";
}

.ordAlertCustom {
  background-color: #f39682;
  color: white;
}

/**
 * ==============================================
 * Dot Pulse
 * ==============================================
 */
.dotPulse {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  box-shadow: 9999px 0 0 -5px #9880ff;
  animation: dotPulse 1.5s infinite linear;
  animation-delay: 0.25s;
}

.ordGrayInput {
  color: #58595b !important;
}

.pdLeft5px {
  padding-left: 5px;
}

.pdRight5px {
  padding-left: 5px;
}

.topRightCorner {
  position: fixed;
  top: 0;
  right: 0;
}

.topLeftCorner {
  position: fixed;
  top: 0;
  left: 0;
}

.bottomRightCorner {
  position: fixed;
  bottom: 0;
  right: 0;
}

.bottomLeftCorner {
  position: fixed;
  bottom: 0;
  left: 0;
}

.ordLeftLineBlue {
  border-left: 5px solid $ordAquaMarine;
  height: auto;
}

.multiTabScroll {
  height: 141vh;
  max-height: 125vh;
  overflow-y: scroll;
}

/* styles register appointment */

.app_container_close_app {
  display: flex;
  padding: 10px 16px;
  align-items: center;
  justify-content: flex-end;
}

.app_close {
  width: 15px;
  height: 15px;
  cursor: pointer;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("../../assets/img/icons/appClosePatient.svg");

  &:hover {
    background-image: url("../../assets/img/icons/close-blue.svg");
  }
}

.app_container_register_patient {
  width: 100%;
  margin: auto;
  padding: 0.5rem;
  max-width: 560px;
}

.app_container_options_register {
  display: flex;
  width: 100%;
  margin: auto;
  max-width: 340px;
  align-items: center;
  justify-content: space-between;
}

.app_container_appointment_info {
  width: 100%;
  margin: auto;
  max-width: 360px;
  padding: 0.5rem 1rem;
}

.app_container_alert_patient {
  gap: 0.5rem;
  display: flex;
  height: 300px;
  align-items: center;
  flex-direction: column;
}

.app_container_button {
  display: flex;
  margin-top: 6px;
  margin-bottom: 6px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.button_assign_appointment {
  width: 100%;
  padding: 1rem;
  height: 100%;
  display: flex;
  max-height: 40px;
  font-weight: 700;
  font-size: 16px;
  color: #ffffff;
  border-radius: 7px;
  font-style: normal;
  align-items: center;
  background: #00b4cc;
  margin-bottom: 10px;
  justify-content: center;
  font-family: "PT Sans Caption";
}

.button_assign_appointment:hover {
  color: #fff;
  background: #1a538d;
}

.button_assign_appointment:disabled {
  color: #bbb;
  background: #eee;
  cursor: default;
}

.inverse {
  color: #fff;
  background: rgb(243, 150, 130);
}

.app_alert_patient {
  padding: 0.8rem;
  border-radius: 5px;
  background: #f39682;
}

.app_alert_patient>span {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #ffffff;
  font-family: "PT Sans Caption";
}

.button_detail_patient {
  width: 100%;
  padding: 1rem;
  height: 100%;
  display: flex;
  max-height: 40px;
  font-weight: 700;
  font-size: 16px;
  color: #1a538d;
  background: #ffffff;
  border-radius: 7px;
  font-style: normal;
  align-items: center;
  justify-content: center;
  border: 1px solid #00b4cc;
  font-family: "PT Sans Caption";
}

.button_detail_patient:hover {
  color: #ffffff;
  background: #00b4cc;
}

.app_container_register_insurance {
  padding: 1rem;
  border-radius: 10px;
  margin-bottom: 0.7rem;
  background: #e6f8fa;
  border: 1px solid #a3e4ec;
}

.app_container_insurance_voluntary {
  padding: 1rem;
  background: #d6e0eb;
  border-radius: 10px;
  margin-bottom: 1rem;
  border: 1px solid #1a538d;
}

.app_inputs_register_patient {
  color: #cbcbd0 !important;
  border-radius: 6px !important;
  background: #ffffff !important;
  border: 0.5px solid #5c84ad !important;
}

.app_inputs_register_patient::placeholder {
  color: #cbcbd0 !important;
}

.app_inputs_register_patient:focus {
  box-shadow: none !important;
}

.app_container_icon_detail {
  gap: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.app_inputs_detail_patient {
  color: #6e6f7c !important;
  border-radius: 6px !important;
  background: #ffffff !important;
  border: 0.5px solid #5c84ad !important;
}

.app_inputs_detail_patient::placeholder {
  color: #6e6f7c !important;
}

.app_inputs_detail_patient:focus {
  box-shadow: none !important;
}

.app_inputs_detail_patient:disabled {
  color: #6e6f7c !important;
  background: #f5f7fa !important;
  border-radius: 6px !important;
  border: 0.7px solid #a3bad1 !important;
}

.app_container_list_app {
  width: 100%;
  display: flex;
  height: 100%;
  overflow: auto;
  max-width: 320px;
  flex-direction: column;
  justify-content: space-between;
  border-right: 5px solid #00b4cc;
}

.app_container_register_app {
  width: 100%;
  overflow: auto;
  max-width: 580px;
  border-right: 5px solid #00b4cc;
}

.app_container_form_register {
  display: grid;
  column-gap: 2rem;
  grid-template-columns: repeat(2, 1fr);
}

.container_title_insurer_voluntary {
  display: flex;
  margin-bottom: 10px;
  align-items: center;
  justify-content: space-between;
}

.app_title_insurer_voluntary {
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 15px !important;
  color: #003f80 !important;
  font-family: "PT Sans Caption" !important;
}

.app_insurer_voluntary {
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  color: #003f80;
  font-family: "PT Sans Caption";
}

.btn_add_insurer {
  width: 20px;
  height: 20px;
  background-size: 20px;
  background-repeat: no-repeat;
  background-image: url("../../assets/img/icons/add-check-blue-dark.svg");
}

.btn_search {
  height: 32px;
  width: 100%;
  max-width: 584px;
  color: #a3bad1;
  border-radius: 5px;
  background-color: #f5f7fa;

  &:focus {
    outline: none;
    box-shadow: none;
  }

  &::placeholder {
    color: #a3bad1;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    font-family: "PT Sans Caption";
  }
}

.btn_save_patient {
  color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  background: #003f80;
}

.app_container_content_form {
  display: flex;
  margin-bottom: 5px;
  flex-direction: column;
}

.app_title_register_app {
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  color: #1a538d;
  font-family: "PT Sans Caption";
}

.app_subtitle_register_app {
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  color: #00b4cc;
  font-family: "PT Sans Caption";
}

.app_subtitle_not_history {
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  color: #6e6f7c;
  font-family: "PT Sans Caption";
}

.app_container_search_app {
  width: 100%;
  margin: auto;
  max-width: 240px;
}

.app_input_search {
  width: 100%;
  height: 32px;
  font-weight: 400;
  font-size: 16px;
  color: #6e6f7c;
  max-width: 176px;
  font-style: normal;
  border-radius: 6px;
  font-family: "PT Sans Caption";
  border: 0.5px solid #5ccfde;
}

.app_input_search:focus-visible {
  outline: none;
}

.app_back_search {
  display: flex;
  padding: 8px 16px;
  border-radius: 10px;
  margin-bottom: 10px;
  background: #e6f8fa;
  flex-direction: column;
  border: 1px solid #a3e4ec;
}

.app_container_icon_search {
  gap: 1rem;
  display: flex;
  align-items: center;
}

.app_title_search {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: #00b4cc;
  font-family: "PT Sans Caption";
}

.app_container_icon_new {
  gap: 0.5rem;
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  &:hover>div {
    background-image: url("../../assets/img/icons/add-check-blue-hover.svg");
  }

  &:hover>span {
    color: #00b4cc;
    text-decoration: underline;
  }
}

.app_container_icon_voluntary {
  gap: 0.5rem;
  display: flex;
  cursor: pointer;
  align-items: center;
  margin-bottom: 10px;
  justify-content: space-between;
}

.app_container_icon_new>span {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #6e6f7c;
  cursor: pointer;
  font-family: "PT Sans Caption";
}

.app_container_card_list_patient {
  width: 100%;
  display: flex;
  cursor: pointer;
  padding: 5px 16px;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #cbcbd0;
  border-bottom: 1px solid #cbcbd0;

  &:hover {
    background: #ececec80;
  }
}

.app_title_list {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  color: #6e6f7c;
  cursor: pointer;
  font-family: "PT Sans Caption";
}

.app_text_list {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #6e6f7c;
  cursor: pointer;
  font-family: "PT Sans Caption";
}

.app_container_list_patient {
  width: 100%;
  height: 190px;
  overflow: auto;
}

.app_container_programming {
  width: 100%;
  max-width: 832px;
  margin: 50px auto auto auto;
}

.app_title_programming {
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  color: #1a538d;
  margin-top: 50px;
  font-family: "PT Sans Caption";
}

.app_container_card_insurer {
  padding: 1rem;
  cursor: pointer;
  border-radius: 10px;
  background: #ffffff;
  margin-bottom: 1rem;
  border: 1px solid #d6e0eb;
}

.app_title_programming_insurer {
  font-family: "PT Sans Caption";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: #1a538d;
}

.app_subtitle_insurer {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #1a538d;
  font-family: "PT Sans Caption";
}

.app_container_list_patient::-webkit-scrollbar {
  width: 14px;
  background-color: #fff;
}

/* background of the scrollbar except button or resizer */
.app_container_list_patient::-webkit-scrollbar-track {
  background-color: #fff;
}

.app_container_list_patient::-webkit-scrollbar-track:hover {
  background-color: #fff;
}

/* scrollbar itself */
.app_container_list_patient::-webkit-scrollbar-thumb {
  background-color: #ccd9e6;
  border-radius: 16px;
  border: 4px solid #fff;
}

.app_container_list_patient::-webkit-scrollbar-thumb:hover {
  background-color: #ccd9e6;
  border: 4px solid #fff;
}

.filterGray {
  filter: grayscale(100%);
}

.dotPulse::before,
.dotPulse::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
}

.dotPulse::before {
  box-shadow: 9984px 0 0 -5px #9880ff;
  animation: dotPulseBefore 1.5s infinite linear;
  animation-delay: 0s;
}

.dotPulse::after {
  box-shadow: 10014px 0 0 -5px #9880ff;
  animation: dotPulseAfter 1.5s infinite linear;
  animation-delay: 0.5s;
}

@keyframes dotPulseBefore {
  0% {
    box-shadow: 9984px 0 0 -5px #9880ff;
  }

  30% {
    box-shadow: 9984px 0 0 2px #9880ff;
  }

  60%,
  100% {
    box-shadow: 9984px 0 0 -5px #9880ff;
  }
}

@keyframes dotPulse {
  0% {
    box-shadow: 9999px 0 0 -5px #9880ff;
  }

  30% {
    box-shadow: 9999px 0 0 2px #9880ff;
  }

  60%,
  100% {
    box-shadow: 9999px 0 0 -5px #9880ff;
  }
}

@keyframes dotPulseAfter {
  0% {
    box-shadow: 10014px 0 0 -5px #9880ff;
  }

  30% {
    box-shadow: 10014px 0 0 2px #9880ff;
  }

  60%,
  100% {
    box-shadow: 10014px 0 0 -5px #9880ff;
  }
}

.ordBgFileTableYellow {
  background-color: #fcf1e1 !important;
}

.ordBgFileTableRed {
  background-color: #fce6e1 !important;
}

.ordSpanText {
  color: #a2a3ab;
  font-size: 20px;
}

.ordGrayInput {
  color: #58595b !important;
}

.ordGrayOrdersInputs {
  color: #757575 !important;
}

.checkCardGreen {
  width: 40px;
  height: 40px;
  cursor: pointer;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(../../assets/img/icons/greenCircleCheck.svg);
}

.checkCardGreen:hover {
  background-image: url(../../assets/img/icons/greenCircleCheckHover.svg);
}

.denyCardRed {
  width: 40px;
  height: 40px;
  cursor: pointer;
  background-image: url(../../assets/img/icons/redClose.svg);
  background-size: contain;
  background-repeat: no-repeat;
}

.denyCardRed:hover {
  background-image: url(../../assets/img/icons/denyCircleHover.svg);
}

.ordGreenButton {
  background-color: #fafdf6;
  border-radius: 10px;
  color: #bce08d;
  width: 100px;
}

.ordGreenBgButton {
  background-color: #fafdf6;
  border-radius: 10px;
  // font-size: 12px;
  color: #bce08d;
  padding: 6px;
}

.ordRedBgButton {
  // background-color: #f397821a;
  background-color: #fef7f5;
  border-radius: 10px;
  // font-size: 12px;
  color: #f39682;
  padding: 6px;
}

.tooltipHover:hover {
  filter: brightness(0) saturate(100%) invert(72%) sepia(45%) saturate(7269%) hue-rotate(149deg) brightness(93%) contrast(107%);
}

.outlineNone:focus-visible {
  outline: none;
}

.ordOrangeButton {
  background-color: #f0c88d2e;
  border-radius: 10px;
  color: #f3c682;
  width: 80px;
}

.ordOrangeButtonWidthAuto {
  background-color: #f0c88d2e;
  border-radius: 10px;
  color: #f3c682;
  width: auto;
  padding: 0px 10px;
}

.ordGrayButtonWidthAuto {
  background-color: #CECECE;
  border-radius: 10px;
  color: #6e6f7c;
  width: auto;
  padding: 0px 10px;
  font-size: 13px;
}

.ordRedButton {
  background-color: #fef7f5;
  border-radius: 10px;
  color: #f39682;
  width: 80px;
}

.ordRedButtonWidthAuto {
  background-color: #fef7f5;
  border-radius: 10px;
  color: #f39682;
  width: auto;
  padding: 0px 10px;
}

.cardCheckAppointment {
  background-color: #f5f7fa;
  border-radius: 16px;
}

.appSearchPatientButton {
  background-repeat: no-repeat;
  background-image: url("../../assets/img/icons/searchPatient.svg");
  background-position: right;
  width: "-webkit-fill-available" !important;
  background-position: 96% 50%;
  margin: auto;
  background-size: 16px;
  padding-right: 40px;
}

.appGrayInputSearch {
  background-color: #f5fcfd;
  border-radius: 6px;
  font-size: 12px;
  color: #a3bad1;
}

.appGrayInputSearch>input {
  border: none;
  color: #a3bad1;
  background-color: #f5fcfd;
  height: 32px;
}

.appGrayInputSearch>img {
  padding-right: 0.5rem;
  color: #a3bad1;
}

.appInputDate {
  color: #a3bad1;
  background-color: #f5fcfd;
  border: none;
  border-radius: 6px;
  font-size: 12px;
  padding: 0px 10px;
  height: 32px;
}

.appInputDate::placeholder {
  color: #a3bad1;
}

.appInputDate:focus {
  outline: none;
}

.blueCardNoBorder {
  padding: auto;
  height: auto;
  background-color: #e6f8fa;
  border-radius: 10px;
}

.blueMediumText {
  color: #1abcd2;
}

.superClearGreyText {
  color: #a2a3ab;
}

.filterHoverBlue:hover {
  filter: invert(43%) sepia(43%) saturate(3118%) hue-rotate(156deg) brightness(104%) contrast(107%);
}

.f32 {
  font-size: 32px;
}

.TableRowHeader:hover {
  color: #1abcd2;
  font-weight: bold;
  text-decoration: underline;
}

.darkBlueHover:hover {
  filter: invert(11%) sepia(47%) saturate(7027%) hue-rotate(201deg) brightness(101%) contrast(101%);
}

.appAddFileButton {
  background-repeat: no-repeat;
  background-image: url("../../assets/img/icons/AdjuntarRecto.svg");
  // background-size: 100%;
  background-position: right;
  width: "-webkit-fill-available" !important;
  background-position: 96% 50%;
  margin: auto;
  background-size: 16px;
  padding-right: 40px;
}

.f24 {
  font-size: 24px;
}

.appDarkBlueText {
  color: #003f80;
}

.heightSelectApp>div {
  height: auto !important;
}

.heightSelectApp>div>div {
  height: 70px !important;
  overflow-y: scroll;
}

.appSchSettButton {
  display: flex;
  padding: 8px 16px;
  border-radius: 10px;
  margin-bottom: 10px;
  background: #e6f8fa;
  flex-direction: column;
  border: 1px solid #a3e4ec;
}

.appSchSettButton:hover {
  background: #a3e4ec;
  border: 1px solid #a3e4ec;
}

.appSchSettButtonActive {
  display: flex;
  padding: 8px 16px;
  border-radius: 10px;
  margin-bottom: 10px;
  background: #00b4cc;
  flex-direction: column;
  border: 1px solid #00b4cc;
}

.appSchSettButtonActive:hover {
  background: #5ccfde;
  border: 1px solid #5ccfde;
}

.appCardBlueDark {
  background: #f5f7fa;

  border: 1px solid #003f80;
  border-radius: 10px;
}

.appInputLine {
  border: none;
  border-bottom: 1px solid #a2a3ab;
}

.appInputFileAdmission {
  border: 1px solid #00b4cc5c;
  border-radius: 5px;
  font-size: 12px;
  height: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 10px;
  padding: 0.25rem 0.4rem;
  overflow: hidden;
  text-overflow: ellipsis
}

.appInputLine::placeholder {
  border: none;
  color: #cecece;
}

.appInputLine:focus {
  border: none;
  border-bottom: 1px solid #a2a3ab;
  outline: none;
}

.textOrangeHover {
  color: #f39682;
}

.textOrangeHover:hover>.AddIconHover:hover {
  color: #00b4cc;
}

.AddIconHover:hover {
  filter: invert(43%) sepia(43%) saturate(3118%) hue-rotate(156deg) brightness(104%) contrast(107%);
}

.appButtonAdd:hover .textOrangeHover {
  color: #00b4cc;
}

.appButtonAdd:hover .AddIconHover {
  filter: invert(43%) sepia(43%) saturate(3118%) hue-rotate(156deg) brightness(104%) contrast(107%);
}

.tooltipAlert>div {
  background-color: #f39682 !important;
  box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.2);
  color: #fff;
}

.svgWhiteFilter {
  // #FFF
  filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(204deg) brightness(103%) contrast(104%);
}

.svgTurquoiseFilter {
  // #00B4CC
  filter: brightness(0) saturate(100%) invert(51%) sepia(89%) saturate(1167%) hue-rotate(146deg) brightness(91%) contrast(102%);
}

.hoverPopup:hover {
  // #00B4CC POPUP HOVER
  filter: brightness(0) saturate(100%) invert(51%) sepia(89%) saturate(1167%) hue-rotate(146deg) brightness(91%) contrast(102%) !important;
}

.appCheckInput[type="checkbox"] {
  border: 2px solid $ordAquaMarine;
  border-radius: 4px;
  background: #fff;
  cursor: pointer;
  height: 16px;
  width: 16px;
}

.appCheckInput[type="checkbox"]:hover {
  opacity: 1;
}

.appCheckInput[type="checkbox"]:checked {
  background-color: #000;
  opacity: 1;
}

.appCheckInput[type="checkbox"]:before {
  // content: '';
  position: absolute;
  right: 50%;
  top: 50%;
  width: 4px;
  height: 10px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  margin: -1px -1px 0 -1px;
  transform: rotate(45deg) translate(-50%, -50%);
  z-index: 2;
}

.appCheckClear {
  background: #ffffff;
  /* Color Principal */
  border: 1.5px solid $ordAquaMarine;
  box-sizing: border-box;
  border-radius: 4px;
}

.appCheckClear:checked {
  /* Color Principal */
  background: #00b4cc;
  border-radius: 4px;
}

.checkClearBlue {
  border: 1.5px solid $ordAquaMarine !important;
}

.checkClearBlue:checked {
  background-color: $ordAquaMarine !important;
  filter: drop-shadow(0px 1px 4px rgba(0, 93, 191, 0.35));
  border-color: $ordAquaMarine;
}

.checkClearBlue:checked:hover {
  background-color: $ordAquaMarine !important;
  border-color: $ordAquaMarine;
}

.checkClearGray {
  border: 2px solid #cbcbd0 !important;
}

.gray {
  // #CBCBD0
  filter: brightness(0) saturate(100%) invert(83%) sepia(5%) saturate(166%) hue-rotate(202deg) brightness(99%) contrast(90%);
}

.checkClearGray:checked {
  border: 2px solid $ordAquaMarine !important;
  background-color: $ordAquaMarine !important;
  filter: drop-shadow(0px 1px 4px rgba(0, 93, 191, 0.35));
  border-color: $ordAquaMarine;
}

.checkClearGray:checked:hover {
  border: 2px solid $ordAquaMarine !important;
  background-color: $ordAquaMarine !important;
  border-color: $ordAquaMarine;
}

.appBillingInput {
  color: #6e6f7c !important;
  border-radius: 6px !important;
  background: #ffffff !important;
  border: 0.7px solid #00b4cc !important;
}

.appBillingInput::placeholder {
  color: #ccd9e6 !important;
}

.appBillingInputFile {
  color: #6e6f7c !important;
  border-radius: 6px !important;
  background: #ffffff !important;
  border: 0.7px solid #00b4cc70 !important;
}

.appBillingInputFile::placeholder {
  color: #ccd9e6 !important;
}

.appBillingInputDate {
  color: #00b4cc !important;
  font-weight: bold;
  border-radius: 6px !important;
  background: #ffffff !important;
  border: 0.7px solid #00b4cc !important;
}

.appBillingInputDate::placeholder {
  color: #ccd9e6 !important;
}

.ordBillingBgBlue {
  // background-color: #d6f3f7;
  background-color: #e6f8fa;
  // border: 1px solid #00b4cc;
  border-radius: 20px;
  text-align: center;
}

.globalUnderline {
  text-decoration: underline;
}

.appButtonHover:hover .appTextHoverTurquoise {
  color: #00b4cc;
}

.appButtonHover:hover .AppIconHoverTurquoise {
  filter: invert(43%) sepia(43%) saturate(3118%) hue-rotate(156deg) brightness(104%) contrast(107%);
}

.appBackBorderBlue {
  background: #f5fcfd;
  border: 1px solid #00b4cc;
  border-radius: 20px;
}

// --------------------- admissions---------------

.addCardSite {
  border: 1px solid #d6e0eb;
  border-radius: 15px;
  width: 192px;
  height: 121px;
  transition: all 0.3s;
}

.addCardSite:hover {
  background-color: #f5f7fa;
}

.calendarContainer {
  padding: 60px 110px;
  width: 100%;
}

.headerCalendarDoctor {
  display: grid;
  grid-template-columns: 42px 1fr;
  height: 42px;
  justify-content: center;
  align-items: center;
  column-gap: 20px;
  margin-bottom: 10px;
}

.headerCalendarSite {
  display: grid;
  grid-template-columns: 1fr;
  height: 42px;
  column-gap: 10px;
  margin-bottom: 10px;
}

.headerCalendarDoctor_logo {
  border-radius: 100%;
  background-color: #ccc;
  height: 100%;
}

.headerCalendarDoctor_nameDoctor {
  color: $darkerBlue;
  font-weight: bold;
  font-size: 24px;
}

.appWatchTime {
  background-color: #00b4cc;
  border-radius: 7px;
  color: #fff;
}

.appWatchTime:hover {
  background-color: #5c84ad;
}

.addBorderLineGray {
  border-bottom: 1px solid #e8e8ea;
}

.addCardWhite {
  border-radius: 16px;
  background-color: #fff;
  box-shadow: 8px 8px 18px -6px rgba(0, 63, 128, 0.12),
    0px 12px 42px -4px rgba(0, 63, 128, 0.12);
}

.addGrayCircle {
  background-color: #f9f9fa;
  border-radius: 24px;
}

.addTextAquaColor {
  color: $ordBgAquaMBlueOpacity40;
}

.appInputLineBlue {
  border: none;
  border-bottom: 1px solid #a2a3ab;
}

.appInputLineBlue::placeholder {
  border: none;
  color: #00b4cc;
}

.appInputLineBlue:focus {
  border: none;
  border-bottom: 1px solid #a2a3ab;
  outline: none;
}

.svgBlueFilter {
  // #003F80
  filter: brightness(0) saturate(100%) invert(17%) sepia(41%) saturate(3948%) hue-rotate(196deg) brightness(95%) contrast(101%);
}

.heightAutoSelectApp>div {
  height: fit-content !important;
}

.heightAutoSelectApp>div>div {
  height: auto !important;
}

.xx {
  flex: 1;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.styleTr {
  display: grid;
  grid-template-columns: 15% 20% 20% 15% 10% 10% 10%;
  align-items: center;
  border-bottom: 2px solid #00b4cc3d;
  padding: 5px;
}

.styleT2 {
  display: grid;
  grid-template-columns: 15% 20% 20% 15% 10% 10% 10%;
  align-items: center;
  padding: 5px;
}

.colorCitas {
  color: "#696969";
}

.appPdfReminderPin {
  background-color: #d6e0eb;
  color: #58595b;
  border-radius: 3px;
  text-decoration: underline;
}

.colorNewOrder {
  display: flex;
  align-items: center;
  justify-content: end;
  color: #697387;
}

.colorNewOrderHover:hover,
.colorNewOrderHover:hover img {
  // color: #00B4CC;
  filter: brightness(0) saturate(100%) invert(70%) sepia(42%) saturate(5635%) hue-rotate(144deg) brightness(89%) contrast(103%);
  cursor: pointer;
}


.iconColorDarkBlue :hover {
  // color: #005dbf;
  filter: brightness(0) saturate(100%) invert(23%) sepia(99%) saturate(5773%) hue-rotate(201deg) brightness(91%) contrast(101%);
  cursor: pointer;
}

.iconColorRed :hover {
  // color: #ff0000;
  cursor: pointer;
  filter: brightness(0) saturate(100%) invert(16%) sepia(88%) saturate(5830%) hue-rotate(357deg) brightness(110%) contrast(117%);
}

.iconColorGreen :hover {
  // color: #00a551;
  cursor: pointer;
  filter: brightness(0) saturate(100%) invert(39%) sepia(76%) saturate(4342%) hue-rotate(136deg) brightness(100%) contrast(103%);
}

.customPopover:hover {
  background-color: #00b4cc;
}

.inputMonthYear {
  border: 1px solid #ccc !important;
  border-radius: 3px;
  padding: 3px;
  color: #939393;
  outline: none;
  text-transform: capitalize;
}

.customPopover:hover>p,
.customPopover:hover>img {
  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%);
}

//ordAquaMarine
.hoverOrdAquaMarine:hover {
  filter: brightness(0) saturate(100%) invert(51%) sepia(70%) saturate(625%) hue-rotate(140deg) brightness(95%) contrast(101%);
}

.backGroundAquamarineHover:hover {
  background-color: #d6f3f769;
}

.customPopover2:hover>p,
.customPopover2:hover>img {
  filter: brightness(0) saturate(100%) invert(25%) sepia(35%) saturate(1993%) hue-rotate(181deg) brightness(99%) contrast(90%);
}

.customPopover {
  align-items: center;
  display: flex;
  margin: 5px auto;
  text-align: center;
  padding: 0px 5px;
  transition: all 0.3s;
}

.pointer {
  cursor: pointer;
}

.colorWhite {
  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%);
}

.colorGray {
  filter: brightness(0) saturate(100%) invert(49%) sepia(6%) saturate(752%) hue-rotate(198deg) brightness(85%) contrast(85%);
}

.colorWhite {
  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%);
}

.popap {
  position: absolute;
  margin-top: 7rem;
  background: white;
  padding: 6px 2px;
  border-radius: 10px;
  box-shadow: 0px 0px 5px #ccc;
  text-align: center;
  font-size: 14px;
}

.poapItem:hover {
  background-color: #00b4cc;
  font-weight: bold;
  color: white !important;
}

.poapItem {
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
  padding: 0px 4px;
}

.bgBox {
  background-color: #f5fcfd;
  padding: 10px;
  border-radius: 10px;
}

.grid2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  gap: 20px;
}

.grid4 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  justify-content: center;
  align-items: center;
}

.infoPatient {
  color: #00b4cc;
  font-weight: bold;
}

.infoPatient2 {
  color: #003f80;
  font-weight: bold;
}

.fs17 {
  font-size: 17px;
}

.btnCancel {
  padding: 10px 20px;
  border: 1px solid #003f80;
  border-radius: 5px;
  color: #003f80;
  font-weight: bold;
  transition: all 0.5s;
}

.btnCancel:hover {
  background-color: #003f80;
  color: white;
}

.btnSave {
  padding: 10px 20px;
  border-radius: 5px;
  color: white;
  font-weight: bold;
  transition: all 0.5s;
  background-color: #00b4cc;
}

.stylesHeaderOrders {
  display: flex;
  align-items: center;
  justify-content: center;
}

// @media screen and (min-width: 1400px) {
//   .stylesHeaderOrders {
//     margin: auto;
//     display: flex;
//     justify-content: center
//   }

// }

.veryClearGrayText {
  color: #aeafca;
}

.alertHeaderLineOrange {
  border-right: 4px solid #f39682;
}

// }
.heightScrollSelectApp>div {
  height: auto !important;
}

.heightScrollSelectApp>div>div {
  height: 60px !important;
  overflow-y: auto;
}

.svgDarkBlueFilter {
  // #005dbf
  filter: brightness(0) saturate(100%) invert(23%) sepia(99%) saturate(5773%) hue-rotate(201deg) brightness(91%) contrast(101%);
}

.nmCardQuestions {
  // border:  1px solid #01A0F6 ;
  border-radius: 15px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
}

.nmCardQuestionsRequired {
  // border:  1px solid #01A0F6 ;
  transition: all 0.5s;
  // background-color: rgba(206, 74, 74, 0.541);
  border-radius: 15px;
  box-shadow: 0px 4px 20px rgba(206, 74, 74, 0.39);
}

.nmCardShortQuestions {
  transition: all 0.5s;
  border-radius: 15px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
  max-height: 517px;
}

.nmShortAnswer {
  transition: all 0.5s;
  // border-radius: 6px;
  background-color: rgba(0, 0, 0, 0.06);
}

.nmShortAnswerContainer {
  transition: all 0.5s;
  max-height: 350px;
  overflow-y: auto;
  // border-radius: 6px;
  // background-color:  rgba(0, 0, 0, 0.06);
}

.registerInputsNom {
  border: 0px;
  border-bottom: 1px solid #7FADDE;
  border-radius: 5px;
  width: 100%;
  padding: 0 0.5rem;
  height: 27px;
  max-height: 27px;
  font-size: 13px;
  color: $darkGray;
  outline: none;
}

.OrdSlideButtonNom {
  background: #f2f7fc;
  box-shadow: 0px 0px 6px rgba(19, 19, 19, 0.2);
  border-radius: 10px;
  margin-top: 16px;
  margin-bottom: 20px;
}

.svgFilterDarkBlue {
  //#005dbf
  filter: brightness(0) saturate(100%) invert(24%) sepia(35%) saturate(5031%) hue-rotate(199deg) brightness(96%) contrast(101%);
}

.textDetailNom {
  text-decoration: underline;
  text-decoration-color: #005dbf;
  cursor: pointer;
}

.icono {
  // background-image: url("../../assets/img/icons/star.svg");
  background: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='55' viewBox='0 0 24 24' width='55' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 0px;
  border: 1px solid #dfdfdf;
  border-radius: 2px;
  margin-right: 2rem;
  padding: 1rem;
  padding-right: 2rem;
}

.W900 {
  width: 900px !important;
}


.nomInput {
  border: 1.5px solid #005dbf;
  border-radius: 5px;
  width: 100%;
  padding: 0 .5rem;
  height: 27px;
  max-height: 27px;
  font-size: 13px;
  color: $darkGray;
}

.nomInput:focus {
  outline: none;
}

.svgFilterColorBlue {
  // #01a0f6
  filter: brightness(0) saturate(100%) invert(40%) sepia(84%) saturate(2005%) hue-rotate(175deg) brightness(106%) contrast(99%);
}

.containerSearch {
  gap: 10px;
  width: 100%;
  display: flex;
  align-items: center;
}

.containerSelect {
  width: 100%;
  max-width: 300px;
}

.containerDate {
  width: 100%;
  max-width: 140px;
}

.containerSearchAdmin {
  width: 100%;
  max-width: 270px;
}

.selectAdministracion {
  height: 27px;
  border-radius: 6px;
  border: 1px solid #005dbf;
}

.containerFranquicia {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.textColorAdministracion {
  color: #005dbf;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  font-family: "PT Sans Caption";
}

.borderAccordion {
  border: 1px solid #00b4cc;
}

.selectedBg {
  background-color: #FCF1E1 !important;
}

.order1 {
  order: 1;
}

.order0 {
  order: 0;
}

.minWidth300px {
  width: 300px !important;
}

.minWidth100px {
  width: 100px !important;
}

.minWidth50px {
  width: 50px !important;
}

.minWidth150px {
  width: 150px !important;
}

.minWidth200px {
  width: 200px !important;
}

.minWidth400px {
  width: 400px !important;
}

.minWidth50px {
  width: 50px !important;
}

.minWidth80px {
  width: 80px !important;
}

.iconWhite {
  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(106%);
}

.outlineDanger {
  outline-color: #dc3545 !important;
}

.cardStyles {
  box-shadow: 0px 5px 10px #ccc;
  border-radius: 15px;
  padding: 15px 20px;
  display: grid;
  grid-template-columns: 1fr;
  background-color: white !important;
}

.hoverClose :hover {
  transform: scale(1.1);
}

.iconUserCircle>img {
  border-radius: 50%;
  border: 3px solid #01A0F6;
  width: 110px;
  height: 110px;
}

.f40 {
  font-size: 40px;
}

.f45 {
  font-size: 45px;
}

.f30 {
  font-size: 30px;
}

.invalidCard {
  transition: 0.3s all ease-in-out;
  background: #FEF3F4;
  box-shadow: 2px 2px 12px 2px rgba(0, 63, 128, 0.08), 2px 4px 8px 2px rgba(0, 63, 128, 0.04);
  border-radius: 10px;
  padding: 16px;

}

.invalidInput {
  border: 0.5px solid #EC1C24;

}

.nomInput {
  border: 1.5px solid #005dbf;
  border-radius: 5px;
  width: 100%;
  padding: 0 .5rem;
  height: 27px;
  max-height: 27px;
  font-size: 13px;
  color: $darkGray;
}

.nomInput:focus {
  outline: none;
}

.labelFilterSwitch {
  color: #5f606a;
  font-weight: bold;
  font-size: 16px;
}

.headerBorderActive {
  border: 1px solid #80808054;
  padding: 5px;
  text-align: center;
  color: #005dbf;
  box-shadow: 0px 0px 0.6px;
  border-bottom: none;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.headerBorderNoActive {
  padding: 5px;
  text-align: center;
  color: #005dbf;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: #F5F7FA;
  border: 1px solid #F5F7FA;
  border-bottom: 1px solid #80808054;
}

.headerBorderNoActive:hover {
  box-shadow: 0px 0px 4px #80808054;
  border: 1px solid #80808054;
  cursor: pointer;
}

.headerBorder {
  border-bottom: 1px solid #80808054;
}

.iconCircle {
  height: 14px;
  width: 14px;
  border-radius: 100%;
  border: 1px solid gray;
  padding-left: 5px;
}

.hoverBlueLight:hover {
  filter: brightness(0) saturate(100%) invert(57%) sepia(95%) saturate(4031%) hue-rotate(173deg) brightness(102%) contrast(102%);
}

.svgFilterColorBlue {
  // #01a0f6
  filter: brightness(0) saturate(100%) invert(40%) sepia(84%) saturate(2005%) hue-rotate(175deg) brightness(106%) contrast(99%);
}

.iconUserCircle>img {
  border-radius: 50%;
  border: 3px solid #01A0F6;
  width: 110px;
  height: 110px;
}

.f40 {
  font-size: 40px;
}

.f30 {
  font-size: 30px;
}

.invalidCard {
  transition: 0.3s all ease-in-out;
  background: #FEF3F4;
  box-shadow: 2px 2px 12px 2px rgba(0, 63, 128, 0.08), 2px 4px 8px 2px rgba(0, 63, 128, 0.04);
  border-radius: 10px;
  padding: 16px;

}

.invalidInput {
  border: 0.5px solid #EC1C24;
  border-radius: 5px;
  width: 100%;
  padding: 0 .5rem;
  height: 27px;
  max-height: 27px;
  font-size: 13px;
  color: $darkGray;

}


.createNomModule {

  padding: 4px 7px;
  color: #58595B;
  font-size: 12px;
  border-radius: 10px;
  height: 24px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.createNomModule:hover {
  background-color: rgba(0, 93, 191, 0.05);
  color: #005DBF;

}

.createNomModule>div {
  background-repeat: no-repeat;
  background-position: center;
  width: 16px;
  height: 16px;
  margin: auto;
  display: none
}

.createNomModule:hover>div {
  background-image: url("../../assets/img/icons/addbuttonhover.svg");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-repeat: no-repeat;
  width: 16px;
  height: 16px;
  width: "-webkit-fill-available";
  margin: auto;
  display: flex
}

.createNomModule>img {
  height: 16px;
}

.createNomModule:hover>img {
  display: none;
  background-color: rgba(0, 93, 191, 0.05);
  filter: brightness(0) saturate(100%) invert(19%) sepia(99%) saturate(6405%) hue-rotate(202deg) brightness(93%) contrast(101%);
}

.createNomModule:hover>span {
  background-color: rgba(0, 93, 191, 0.05);
  filter: brightness(0) saturate(100%) invert(19%) sepia(99%) saturate(6405%) hue-rotate(202deg) brightness(93%) contrast(101%);
}

.SearchNew {
  border: rgba(0, 93, 191, 0.1);
  background-color: rgba(0, 93, 191, 0.1);
  border-radius: 5px;
  width: 100%;
  padding: 0 0.5rem;
  height: 24px;
  color: $darkGray;
  font-size: 12px;
  outline: none;
}

.SearchNewBig {
  border: rgba(0, 93, 191, 0.1);
  background-color: rgba(0, 93, 191, 0.1);
  border-radius: 5px;
  width: 100%;
  padding: 0 0.5rem;
  height: 32px;
  color: $darkGray;
  font-size: 12px;
  outline: none;
}

.SearchNew::placeholder {
  color: #73a5db;
  font-size: 12px;
}

.textareaNom {
  border: 1px solid #7FADDE;
  height: 4rem;
  border-radius: 5px;
  width: 100%;
  padding: 4px;
  outline: none;
  font-size: 12px;
  color: $darkGray
}

.widthSelect {
  width: 170px !important;
}

.titleAdministrative {
  color: #005DBF;
  font-size: 40px;
  font-weight: 700;
}

.cardAdminPortal {
  background: rgba(0, 93, 191, 0.05);
  border-radius: 10px;
}

.cardComponentSecurity {
  border-bottom: 1px solid #CECECE;
  ;
}

.darkBlueDarker {
  color: #005dbf
}

.addButtonAccount {
  color: #005dbf;
  font-size: calc(1.3rem + 0.6vw);
  cursor: pointer;
}
.addButtonAccount:hover {
  color: #01A0F6;
}


.cardStyles2 {
  box-shadow: 0px 0px 6px #13131333;
  border-radius: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
  color: gray;
  min-height: 80px;
  align-items: center;
  padding: 15px 20px;
}

.tabHeader {
  margin: 0px;
  color: $darkBlue;
  margin-top: 15px;
  margin-bottom: 15px;
  text-align: 'center'
}

.tabActive {
  border: 1px solid $darkBlue;
  padding: 5px 0px;
  border-radius: 10px 10px 0px 0px;
  border-bottom: 0px;
  text-align: center;
}

.tabNoActive {
  cursor: pointer;
  background: #005dbf0d;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px 10px 0px 0px;
  border-bottom: 1px solid $darkBlue
}

.darkBlueText2 h1 {
  color: $darkBlue !important;
}

.cancelFocus:focus {
  box-shadow: none;
  border-color: #8585855c;
}

.stylesDeshabilitar {
  color: rgb(243, 150, 130);
  background-color: rgb(254, 247, 245);
  border-radius: 10px;
  padding: 2px 22px;
}

.marginArrow {
  margin-bottom: 1rem;
  margin-top: 3.8rem;
  margin-left: -2rem;
  margin-right: 1rem;
  text-align: center;
  font-weight: bold;
}

.stylesHabilitar {
  color: #bce08d;
  background-color: #fafdf6;
  border-radius: 10px;
  padding: 2px 22px;
  text-align: center;
  font-weight: bold;
}

.titleAdministrative {
  color: #005DBF;
  font-size: 40px;
  font-weight: 700;
}

.cardAdminPortal {
  background: rgba(0, 93, 191, 0.05);
  border-radius: 10px;
}

.cardComponentSecurity {
  border-bottom: 1px solid #CECECE;
  ;
}

.darkBlueDarker {
  color: #005dbf
}

.marginArrow {
  margin-bottom: 1rem;
  margin-top: 3.8rem;
  margin-left: -2rem;
  margin-right: 1rem;
}

.marginBackIcon {
  margin-left: -2.5rem;
  margin-right: 1rem
}

.iconUserCircleAccounting>img {
  border-radius: 50%;
  border: 3px solid #01A0F6;
  width: 70px;
  height: 70px;
}

.hoverOpacity7:hover {
  opacity: 0.7;
}

.placeHolderInputDateAdmision {
  color: $colorInputDateAdmision !important
}

.inputTextAdmision {
  border-radius: 5px;
  color: $ordDarkGray;
  border: 1px solid #a3bad1;
  height: 32px;
  padding-left: 5px;
}

.boxShadowNone {
  box-shadow: none !important;
}

.ellipsisAuto {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.filterOrdDarkGrayText {
  filter: brightness(0) saturate(100%) invert(45%) sepia(7%) saturate(607%) hue-rotate(198deg) brightness(95%) contrast(86%);
}

.hoverFilterOrdAquaMarine:hover {
  filter: brightness(0) saturate(100%) invert(48%) sepia(98%) saturate(1169%) hue-rotate(148deg) brightness(94%) contrast(101%);
}


.filterGrayDirectionDate {
  filter: brightness(0) saturate(100%) invert(92%) sepia(15%) saturate(518%) hue-rotate(179deg) brightness(94%) contrast(91%);
}

.tableAdmission:hover img {
  filter: brightness(0) saturate(100%) invert(48%) sepia(98%) saturate(1169%) hue-rotate(148deg) brightness(94%) contrast(101%);
}

.tableAdmissionOptional:hover> :last-child img {
  filter: brightness(0) saturate(100%) invert(48%) sepia(98%) saturate(1169%) hue-rotate(148deg) brightness(94%) contrast(101%);
}

.layoutBase {
  padding: 30px 100px 30px 30px !important;
  width: 100% !important;
}

.layoutBaseOutPaddingBottom {
  padding: 30px 100px 0px 30px !important;
  width: 100% !important;
}


.layoutBaseNew {
  margin: 30px 100px 30px 30px !important;
  width: 900px !important;
}

.filterHoverDarkerBlue:hover {
  filter: brightness(0) saturate(100%) invert(15%) sepia(46%) saturate(3605%) hue-rotate(196deg) brightness(105%) contrast(101%);
}

.placeHolderInputDateAdmision {
  color: $colorInputDateAdmision !important
}

.inputTextAdmision {
  border-radius: 5px;
  color: $ordDarkGray;
  border: 1px solid #a3bad1;
  height: 32px;
  padding-left: 5px;
}

.ml2rem {
  margin-left: 2rem;
}

.fs18px {
  font-size: 18px;
}

.fs13px {
  font-size: 13px;
}

.w170px {
  width: 170px !important;
}

.bgTransparent {
  background: transparent !important;
}

.btnReplace {
  padding: 10px;
  border: 2px solid $ordAquaMarine;
  color: $ordAquaMarine;
  font-weight: bold;
  border-radius: 5px;
  transition: all 0.3s;
  min-width: 100px;
  cursor: auto;
}

.btnReplace:hover img {
  filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(3%) hue-rotate(189deg) brightness(103%) contrast(100%);
  cursor: pointer !important;
}

.btnReplaceHover:hover {
  color: white;
  background-color: $ordAquaMarine;
  cursor: pointer !important;
}

.btnReplace:disabled {
  border: 2px solid $clearGray;
  color: $clearGray;
  opacity: 1;
  background-color: transparent !important;
}

.btnReplace:disabled img {
  filter: brightness(0) saturate(100%) invert(100%) sepia(70%) saturate(8%) hue-rotate(295deg) brightness(85%) contrast(90%);
}

.btnReplaceCancel {
  padding: 10px;
  border: 1px solid $darkerBlue;
  color: $darkerBlue;
  font-weight: bold;
  border-radius: 5px;
  transition: all 0.3s;
  min-width: 100px;
}

.btnReplaceCancel:hover {
  color: white;
  background-color: $darkerBlue;
  opacity: 0.6;
}

.btnCancelAdmission {
  padding: 10px;
  border: 1px solid $ordAquaMarine;
  color: $ordAquaMarine;
  font-weight: bold;
  border-radius: 5px;
  transition: all 0.5s;
  min-width: 100px;
}

.btnCancelAdmission:hover {
  color: white;
  background-color: $ordAquaMarine;
}



.btnSaveAdmission {
  color: white;
  padding: 10px;
  border: 1px solid $ordAquaMarine;
  background-color: $ordAquaMarine;
  font-weight: bold;
  border-radius: 5px;
  transition: all 0.5s;
  min-width: 100px;
}

.btnSaveAdmission:hover {
  opacity: 0.6;
}

.filterBlockAdmission {
  filter: brightness(0) saturate(100%) invert(89%) sepia(7%) saturate(119%) hue-rotate(202deg) brightness(92%) contrast(91%);
}

.textCountNotes {
  font-weight: bold;
  color: white;
  margin-left: -15px;
  margin-top: 1px;
}

.textCountNotes2 {
  color: white;
  margin-left: -14px;
  margin-top: -1px;
  position: absolute;
  font-size: 11px;
  top: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  text-align: center;
}

.styleIconCloseFile:hover {
  filter: brightness(0) saturate(100%) invert(61%) sepia(89%) saturate(4059%) hue-rotate(153deg) brightness(100%) contrast(101%);
}

.filterOrdAquaMarine {
  filter: brightness(0) saturate(100%) invert(61%) sepia(89%) saturate(4059%) hue-rotate(153deg) brightness(100%) contrast(101%);
}

.filterDarkBlue {
  filter: brightness(0) saturate(100%) invert(16%) sepia(75%) saturate(7498%) hue-rotate(202deg) brightness(95%) contrast(101%);
}

.filterWhite {
  filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(3%) hue-rotate(189deg) brightness(103%) contrast(100%);
}

.filterWhiteImg img {
  filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(3%) hue-rotate(189deg) brightness(103%) contrast(100%);
}

.filterGrayLight {
  filter: brightness(0) saturate(100%) invert(90%) sepia(10%) saturate(238%) hue-rotate(170deg) brightness(101%) contrast(87%);
}

.closeDrawer:hover {
  background-color: $ordAquaMarine;
  border-radius: 100%;
  height: 28px;
  width: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(3%) hue-rotate(189deg) brightness(103%) contrast(100%);
}

.closeDrawerAsistencial:hover {
  background-color: #1a538d24;
  border-radius: 100%;
  height: 28px;
  width: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  filter: brightness(0) saturate(100%) invert(27%) sepia(12%) saturate(5684%) hue-rotate(182deg) brightness(93%) contrast(91%);
}

.hoverFilterDarkBlue:hover {
  filter: brightness(0) saturate(100%) invert(16%) sepia(75%) saturate(7498%) hue-rotate(202deg) brightness(95%) contrast(101%);
}

.boxTextarea {
  background-color: #00b4cc29 !important;
  padding: 10px 15px;
  border-radius: 5px;
  border: 1px solid $ordAquaMarine;
}

.textareaStyle {
  border: 0.7px solid #99BEE5 !important;
}

.textareaStyle::placeholder {
  color: #CBCBD0 !important;
}

.borderServeOrderReplace {
  border-right: 2px solid #D6E0EB;
}

.bgTabOrderReplace {
  background-color: #F6FEFF;
}

.br15px {
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
}

.formCheckInputRadioAsistencial input[type='radio'] {
  appearance: none;
  border-radius: 50%;
  border: 2px solid #00b4cc;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}


.formCheckInputRadioAsistencial input[type="radio"]:checked::after {
  display: flex;
  content: "";
  position: relative;
  background: #00b4cc;
  border: 4px solid #00b4cc;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
}


.radioClass {
  min-height: 15px;
  display: flex;
  justify-content: center;
  align-items: center
}

.bgTabOrderReplaceRowSelected {
  background-color: #fcf1e1 !important;
}

.replaceChildrenScroll {
  max-height: 480px;
  overflow: auto;
  margin-bottom: 8px !important;
}


.cardCoins {
  margin-bottom: 25px;
  display: grid;
  grid-template-columns: 150px 1fr;
  box-shadow: 0px 0px 15px #ccc;
  border-radius: 10px;
  padding: 15px 20px 15px 10px;
  position: relative;
}

.cardCoinsImg {
  box-shadow: rgb(204, 204, 204) 0px 0px 5px;
  margin: 10px 25px 9px 15px;
  border-radius: 10px;
  display: flex;
  align-items: center;
}

.cardMessageBlue {
  background: #005dbf1f;
  padding: 2px 8px;
  text-align: center;
  color: $darkBlue ;
  border-radius: 10px;
  max-width: 140px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.borderRightSettingDetail {
  border-right: 3px solid #005dbf;
  position: absolute;
  width: 320px;
  height: 92.2vh;
}

.boxBorderColorSettingDetail {
  border: 1px solid #01a0f6;
  background-color: #f2f7fc;
  width: 250px;
  margin: auto;
  padding: 10px 15px;
}


.bgColorSettingDetail {
  background-color: #f2f7fc;
}

.styleBoxSettingDetail {
  margin-left: 320px;
}

.radioCircle {
  height: 7px;
  width: 7px;
  border-radius: 100%;
  background: $darkBlue
}

.colorOrange {
  filter: brightness(0) saturate(100%) invert(70%) sepia(28%) saturate(895%) hue-rotate(319deg) brightness(97%) contrast(96%);
}

.hoverTextBlockSettingSupport:hover {
  transition: 0.3s;
  background-color: $darkBlue !important;
  color: white !important;
  cursor: default;
}

.cursorDefault {
  cursor: default;
}

.blockFieldSettingSupport:disabled {
  background-color: #ebf1f7 !important;
}

.blockFieldSettingSupportSelect>div :first-child {
  background-color: #ebf1f7 !important;
}

.textClearBlue {
  color: $clearBlue !important;
}

.iconFileStyle {
  height: 16px;
  transform: rotateZ(25deg);
  cursor: pointer;
}

.crudModalZonaCuenta2 {
  background-color: rgba(0, 93, 191, 0.05);
  border-radius: 0.5rem;
  padding: 10px 20px;
}

.labelFileStyle {
  font-size: 12px;
  height: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.25rem 0.4rem;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: white;
}

.hoverFilterDarkBlue:hover {
  filter: brightness(0) saturate(100%) invert(16%) sepia(75%) saturate(7498%) hue-rotate(202deg) brightness(95%) contrast(101%);
}

.colorOrange {
  filter: brightness(0) saturate(100%) invert(70%) sepia(28%) saturate(895%) hue-rotate(319deg) brightness(97%) contrast(96%);
}

.h27 {
  height: 27px !important;
  max-height: 27px !important;
}

.h32 {
  height: 32px !important;
  max-height: 32px !important;
}

.w220px {
  width: 220px !important;
}

.stylesLabel {
  color: #005dbf;
  font-size: 0.75rem
}

.w15px {
  width: 15px !important;
}

.darkGrayFilter {
  filter: brightness(0) saturate(100%) invert(43%) sepia(2%) saturate(2757%) hue-rotate(198deg) brightness(99%) contrast(79%);
}

.orangeLightText {
  color: #F39682;
}

.w420px {
  max-width: 420px !important;
  width: 100%;
}

.ordAquaMarineTextIconHover:hover {
  color: $ordAquaMarine !important;
  cursor: pointer;
}

.ordAquaMarineTextIconHover:hover img {
  filter: brightness(0) saturate(100%) invert(61%) sepia(89%) saturate(4059%) hue-rotate(153deg) brightness(100%) contrast(101%) !important;
}

.styleInputText {
  color: #6c757d !important;
  height: 32px !important;
  border-radius: 5px !important;
  padding: 5px !important;
  border: 1px solid hsl(0, 0%, 80%);
}

.styleInputText::placeholder {
  color: #CBCBD0;
  font-size: 14px;
}

.styleCardConsent {
  box-shadow: rgb(204, 204, 204) 0px 0px 5px;
  padding: 15px;
  border-radius: 10px;
  display: grid;
  grid-template-columns: 2fr 1fr;
  // height: 150px;
  margin: 20px 0 10px 0
}

.darkerGrayText {
  color: $darkerGray !important;
}

.footerBottomConsent {
  height: 8vh;
  position: absolute;
  right: 100px;
  width: 100%;
  bottom: 0
}

.ordAquaMarineTextImportant {
  color: $ordAquaMarine;
  font-weight: bold;
}

.marginBottom15px {
  margin-bottom: 15px;
}

.darkGrayFilter {
  filter: brightness(0) saturate(100%) invert(43%) sepia(2%) saturate(2757%) hue-rotate(198deg) brightness(99%) contrast(79%);
}

.orangeLightText {
  color: #F39682
}

.tableAdmission:hover img {
  filter: brightness(0) saturate(100%) invert(48%) sepia(98%) saturate(1169%) hue-rotate(148deg) brightness(94%) contrast(101%);
}

.filterGrayDirectionDate {
  filter: brightness(0) saturate(100%) invert(92%) sepia(15%) saturate(518%) hue-rotate(179deg) brightness(94%) contrast(91%);
}

.filterActiveDailyClosing {
  background: $ordAquaMarine;
  color: #fff !important;
}

.ordAquaMarineBgHover:hover {
  background-color: $ordAquaMarine !important;
}

.btnBlue {
  padding: 10px;
  border: 1px solid $darkerBlue;
  color: $darkerBlue;
  font-weight: bold;
  border-radius: 5px;
  transition: all 0.3s;
  min-width: 100px;
  height: 40px;
}

.btnBlue:hover {
  color: white;
  background-color: $darkerBlue;
}



.btnOrange {
  padding: 10px;
  border: 1px solid #F39682;
  color: #F39682;
  font-weight: bold;
  border-radius: 5px;
  transition: all 0.3s;
  min-width: 100px;
  height: 40px;
}

.btnOrange:hover {
  color: white;
  background-color: #F39682;
}

.btnOrange:hover>img {
  filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(3%) hue-rotate(189deg) brightness(103%) contrast(100%);
}


.btnGreen {
  padding: 10px;
  border: 1px solid #83C036;
  color: #83C036;
  font-weight: bold;
  border-radius: 5px;
  transition: all 0.3s;
  min-width: 100px;
  height: 40px;
}

.btnGreen:hover {
  color: white;
  background-color: #83C036;
}

.btnGreen:hover>img {
  filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(3%) hue-rotate(189deg) brightness(103%) contrast(100%);
}

.btnIconWhiteHover:hover>img {
  filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(3%) hue-rotate(189deg) brightness(103%) contrast(100%);
}

.filterGreenIconSuccess {
  filter: brightness(0) saturate(100%) invert(72%) sepia(7%) saturate(4029%) hue-rotate(43deg) brightness(99%) contrast(74%);
}

.responsiveImg {
  width: 100%;
  height: 300px;
  object-fit: contain;
}

.linkDownload {
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  gap: 6px;

  :hover {
    color: $ordAquaMarine !important;
    text-decoration: underline;
  }
}


.ordAquaMarineBgHover:hover {
  background-color: $ordAquaMarine !important;
}

.blendMode {
  mix-blend-mode: darken
}

.filterOrdAquaMarine {
  filter: brightness(0) saturate(100%) invert(46%) sepia(73%) saturate(1250%) hue-rotate(151deg) brightness(97%) contrast(101%);
}

.stylesCloseCiteSchedule {
  height: 30px;
  width: 30px;
  position: absolute;
  right: 2px;
  top: 0;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    transform: scale(1.1);
    filter: grayscale(1);
  }
}

.tabActiveAdmition {
  border: 1px solid #00B4CC;
  padding: 5px 0px;
  border-radius: 10px 10px 0px 0px;
  border-bottom: 0px;
}

.tabInactiveAdmition {
  cursor: pointer;
  background: rgb(0 180 204 / 9%);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px 10px 0px 0px;
  border-bottom: 1px solid #00B4CC;
}

.rowTabStyle {
  margin: 0px;
  color: #00B4CC;
  margin-top: 15px;
  margin-bottom: 15px;
  text-align: center
}

.hoverTextWhite:hover {
  color: white !important;
}

.stylePopoverAsistencial:hover {
  background-color: $ordAquaMarine;
  color: white;
}

.stylePopoverAsistencial:hover img {
  filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(3%) hue-rotate(189deg) brightness(103%) contrast(100%);
}

.stylePopoverAsistencial img {
  filter: brightness(0) saturate(100%) invert(46%) sepia(17%) saturate(420%) hue-rotate(182deg) brightness(94%) contrast(94%);
}

.filterOrdAquaMarine {
  filter: brightness(0) saturate(100%) invert(46%) sepia(73%) saturate(1250%) hue-rotate(151deg) brightness(97%) contrast(101%);
}

.stylesCloseCiteSchedule {
  height: 30px;
  width: 30px;
  position: absolute;
  right: 2px;
  top: 0;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    transform: scale(1.1);
    filter: grayscale(1);
  }
}

.styleCloseIconCardCollectionsDynamic {
  position: absolute;
  right: -50px;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
}

.ckEditorStyles>.ck-rounded-corners>.ck.ck-editor__top>.ck-sticky-panel>.ck-toolbar>.ck.ck-editor__top>.ck-sticky-panel>.ck-toolbar>.ck-rounded-corners {
  border-radius: 10px;
}

.renderReport {
  background-color: $white;
  border-radius: 10px;
  border: 4px solid $clearGray
}

.bgClearGray {
  background-color: $diabledBg;

}

.variablesStyles {
  padding: 6px;
  border-radius: 10px;
  font-size: 12px;
  background-color: $diabledBg;
}

.app_back_body {
  width: 100%;
}

.selectMenu {
  position: absolute;
  z-index: 1000;
  /* Asegúrate de que esté por encima de otros elementos */
  background-color: white;
  /* Fondo blanco o el que desees */
  /* Agrega otros estilos personalizados según tus necesidades */
}

.buttonSituationDisabled {
  border-radius: 6px;
  font-size: 11px;
  padding: 4px;
  width: 100%;
  border: 1px solid #69738760;
  color: $ordDarkBlueText;
}

.buttonSituationActive {
  border-radius: 6px;
  font-size: 11px;
  padding: 4px;
  width: 100%;
  background-color: #00b4cc20;
  border: 1px solid #00b4cc20;
  color: $ordDarkBlueText;
}

.percentStyle {
  position: absolute;
  right: 0px;
  height: 30px;
  padding: 0px 5px;
  border-left: 1px solid #7FADDE;
}

.hideInputTime::-webkit-calendar-picker-indicator {
  display: none;
}

.buttonTextPrimary {
  background-color: transparent;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #666;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 4px;
  font-weight: 600;
  user-select: none;

  &:hover {
    background-color: rgba($color: #005dbf, $alpha: 0.05);
    color: #005dbf !important;
  }
}

.variableInput {
  padding: 0 5px;
  background: white !important;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  color: rgba(0, 63, 128, 0.5) !important;
  border: 1px solid #a3bad1;
  height: 32px;
  display: flex;
  align-items: center;
  overflow-y: hidden;
  overflow-x: auto;
  flex: 1;
  box-sizing: border-box;

  &::-webkit-scrollbar {
    height: 5px !important;
  }
}

.ghostInput {
  flex: 1;
  cursor: text;
  height: 100%
}

.variableSelect {
  @extend .variableInput;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  user-select: none;
  -webkit-user-select: none;
  cursor: pointer;
  font-size: 14px;
}

.variableOption {
  width: 100%;
  padding: 5px 10px;
  user-select: none;
  -webkit-user-select: none;
  cursor: pointer;
  color: inherit !important;
  font-size: 14px;
  flex-shrink: 0;

  &:hover {
    background-color: #E6EFF9;
  }
}

.plainInput {
  height: 100%;
  border: 0;
  font-size: 14px;
  flex-shrink: 0;
}

.inputVariable {
  display: inline-flex;
  background: #E6EFF9;
  color: #005dbf;
  border-radius: 100px;
  border: 0;
  resize: horizontal;
  font-weight: 600;
  font-size: 14px;
  padding: 0px 10px;
  margin: 0 2px;
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  flex-shrink: 0;

  &:focus {
    outline: 2px solid #005dbf;
  }
}

.inputVariableCareTheme {
  display: inline-flex;
  background: #d6f3f7;
  color: #00b4cc;
  border-radius: 100px;
  border: 0;
  resize: horizontal;
  font-weight: 600;
  font-size: 12px;
  padding: 0px 10px;
  margin: 0 2px;
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  flex-shrink: 0;
}

.inputVariableFocus {
  outline: 2px solid #00b4cc !important;
}

.text_primary_alt {
  color: #01a0f6 !important;
}

.text_blueHC {
  color: $turquoise_100
}

.odd_rows>div:nth-child(even) {
  background-color: #f5f7fa;
}

.hoverable_row:hover {
  background-color: #f5f7fa;
  border-radius: 5px;
}

.muted td {
  opacity: 0.4;
}

.hoverable:hover {
  cursor: pointer;
  background-color: rgba($primary_color, $alpha: 0.1);
}

.message_icon {
  width: 26px;
  height: 20px;
  position: relative;
  border-radius: 2px;
  background-color: $primary_color;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $white;
  font-weight: bold;
  user-select: none;
  cursor: pointer;
  z-index: 10;
  font-size: 14px;
  flex-shrink: 0;

  &::after {
    content: "";
    position: absolute;
    bottom: -5px;
    right: 0;
    width: 0;
    height: 0;
    border-top: 10px solid $primary_color;
    border-left: 10px solid transparent;
  }
}


.tabActive_asistencial {
  border-top: 1px solid #00b4cc;
  border-left: 1px solid #00b4cc;
  border-right: 1px solid #00b4cc;
  border-radius: 10px 10px 0px 0px;
  font-size: 11px;
  padding: 7px 4px;
  background-color: #fff;
  color: #00b4cc;
  cursor: pointer;
}

.tabDisable {
  font-size: 11px;
  border-radius: 10px 10px 0px 0px;
  padding: 7px 4px;
  background-color: #00b4cc20;
  border-bottom: 1px solid #00b4cc;
  color: #00b4cc;
  cursor: pointer;
}
